<template>
  <canvas
    :width="canvasWidth | devicePixelRatio "
    :height="canvasHeight | devicePixelRatio "
    :style="{ 'background-image': `url(${ background })`,
              width: canvasWidth + 'px',
              height: canvasHeight + 'px' }"
    :rows="rows"
    :cols="columns"
    :top="top"
    :left="left">
  </canvas>
</template>

<script>
import { MODE, PHOTO_WIDTH, PHOTO_HEIGHT } from '../default.setting';

export default {
  props: {
    width: Number,
    height: Number,
    background: {},
    settingPanel: Object,
    aspectRatio: Number,
    columns: Number,
    rows: Number,
    top: Number,
    left: Number
  },
  data() {
    return {
      // ctx: this.$el.getContext('2d')
    }
  },
  mounted() {
    this.drawTable();
  },
  updated() {
    this.drawTable();
  },
  methods: {
    drawTable() {
      const { ctx, columns, rows, deviceCanvasWidth, deviceCanvasHeight, mosaicWidth, mosaicHeight, mosaicTop, mosaicLeft } = this;
      ctx.clearRect(0, 0, this.canvasWidth * devicePixelRatio, this.canvasHeight * devicePixelRatio);
      ctx.save();
      ctx.translate(mosaicLeft * devicePixelRatio, mosaicTop * devicePixelRatio);
      ctx.fillStyle = `rgba(255,255,255,0.5)`;
      ctx.fillRect(0, 0, mosaicWidth * devicePixelRatio, mosaicHeight * devicePixelRatio);
      ctx.beginPath();
      // console.log('this.devicePixelRatio', devicePixelRatio);
      for(let i = 0; i <= columns; i++) {
        const columnPos = mosaicWidth / columns * i * devicePixelRatio;
        ctx.moveTo(columnPos, 0);
        ctx.lineTo(columnPos, mosaicHeight * devicePixelRatio);
      }
      for(let i = 0; i <= this.rows; i++) {
        const rowsPos = mosaicHeight / rows * i * devicePixelRatio;
        ctx.moveTo(0, rowsPos);
        ctx.lineTo(mosaicWidth * devicePixelRatio, rowsPos);
      }
      ctx.lineWidth = "0.5";
      ctx.strokeStyle = "black";
      ctx.stroke();
      ctx.closePath();

      const photoWidth = mosaicWidth / columns / 2;
      const photoHeight = mosaicHeight / rows / 2;
      ctx.font = `${ mosaicHeight / rows / 5 * devicePixelRatio }px NanumGothic`;
      ctx.textAlign = "center";
      ctx.fillStyle = `black`;
      for(let i = 0; i < columns; i++) {
        for(let j = 0; j < rows; j++) {          
          ctx.fillText(j * columns + i + 1, (mosaicWidth / columns * i + photoWidth) * devicePixelRatio, (mosaicHeight / rows * j + photoHeight) * devicePixelRatio);
        }
      }
      ctx.restore();
    },
  },
  computed: {
    canvasWidth() {
      return this.settingPanel.canvasWidth;
    },
    canvasHeight() {
      return this.settingPanel.canvasWidth / this.aspectRatio;
    },
    deviceCanvasWidth() {
      return this.canvasWidth * devicePixelRatio;
    },
    deviceCanvasHeight() {
      return this.canvasHeight * devicePixelRatio;
    },
    mosaicWidth() {
      return this.canvasWidth * (this.columns * PHOTO_WIDTH / this.width);
    },
    mosaicHeight() {
      return this.canvasHeight * (this.rows * PHOTO_HEIGHT / this.height);
    },
    mosaicTop() {
      return this.top / ((this.columns * PHOTO_WIDTH) / this.mosaicWidth) ;
    },
    mosaicLeft() {
      return this.left / ((this.rows * PHOTO_HEIGHT) / this.mosaicHeight) ;
    },
    ctx() {
      return this.$el.getContext('2d');
    },
    // computedBackgroundURL() {
    //   return this.$fileToUrl(this.background);
    // }
    drawRows() {
      return this.rows;
    }
  },
  filters: {
    cmToPx(v) {
      return v * 37.79;
    },
    devicePixelRatio(v) {
      return v * window.devicePixelRatio;
    }
  }

}
</script>

<style lang="scss" scoped>
  canvas {
    background: no-repeat center / cover;
    border: 1px solid #000;
    transition: width 0.3s, height 0.3s;
  }
</style>