<template>
  <div class='setting-panel' v-bar>
    <div class='setting-contents'>
      <div class='setting-body'>
        <p>Mosaic is special event that show people a completed image with many pieces of mosaic photo.</p>
      </div>
      <div class='setting-title'>
        Mosaic Size
      </div>
      <div class='setting-body'>
        
        <p>
          <hs-checkbox v-model="setting.use" @change="useMosaic">Use mosaic</hs-checkbox>
        </p>
        <template v-if="setting.use">
          <p><b>Upload Image</b></p>
          <p><hs-file v-model="setting.mosaicImage" @changed="saveMosaicImageURL"></hs-file></p>
          <p>
            <hs-number v-model="setting.cols" @changed="reflectWidthCm" :maxlength="3" :min="1" :max="300">cols</hs-number>
            <hs-number v-model="setting.rows" @changed="reflectHeightCm" :maxlength="3" :min="1" :max="300">rows</hs-number>
          </p>
          <div>
            <dt>Width</dt>
            <dd><hs-float v-model="setting.width" @changed="reflectColumns" :size="6" :min="7.62" :max="2286">cm</hs-float></dd>
            <dt>Height</dt>
            <dd><hs-float v-model="setting.height" @changed="reflectRows" :size="6" :min="10.16" :max="3048">cm</hs-float></dd>
            <dt>Top</dt>
            <dd><hs-float v-model="setting.top" :size="6" :min="0" :max="heightPaddingMax">cm</hs-float></dd>
            <dt>Left</dt>
            <dd><hs-float v-model="setting.left" :size="6" :min="0" :max="widthPaddingMax">cm</hs-float></dd>
          </div>
          
          <p><button @click="makeMosaic">Download</button></p>
          <!-- <p><button @click="saveMosaic">Save Mosaic(Initialize)</button></p> -->
          <hs-mosaic-canvas
            :width="Number(placeholderWidth)"
            :height="Number(placeholderHeight)"
            :background="getMosaicImage"
            :setting-panel="settingPanel"
            :aspect-ratio="placeholderWidth / placeholderHeight"
            :columns="setting.cols"
            :rows="setting.rows"
            :top="setting.top"
            :left="setting.left"
            @click.native="controlMosaic(true)"></hs-mosaic-canvas>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from 'axios';
  import mosaicGen from '../lib/mosaic-gen';
  import config from '../config';
  import { IMAGE_TO_CANVAS_RATIO, PHOTO_WIDTH_RATIO, PHOTO_HEIGHT_RATIO } from '../default.setting';

  export default {
    created() {
    },
    data() {
      return {
        mosaicWidth: 0,
        mosaicHeight: 0,
        onControl: false,
      }
    },
    methods: {
      controlMosaic(v) {
        console.log('controlMosaic');
        if (v) {
          this.$blockOn({
            text:'LOADING',
            delay: 3000,
            color: 'orange',
            sync: true,
          });
        }
        this.settingPanel.onMosaicControl = v;
      },
      fixed(v) {
        return Number(v.toFixed(2));
      },
      makeMosaic(e) {
        this.$blockOn({
          text:'Saving',
          color: 'orange',
          sync: true,
        });
        const url = this.getMosaicImage;
        
        mosaicGen.getBase64FromURL(url, this.placeholderWidth, this.placeholderHeight, this.setting.cols, this.setting.rows, this.setting.top, this.setting.left, this.getMosaicFileName, { toastShow: this.$toastShow, blockOff: this.$blockOff });

      },
      reflectWidthCm(v) {
        const { width } = this.getMosaicSize;
        const tempWidth = Number((v * IMAGE_TO_CANVAS_RATIO * PHOTO_WIDTH_RATIO).toFixed(2));
        if (this.setting.width < tempWidth) {
          this.setting.width = this.fixed(tempWidth);
          this.setting.left = 0;
        } else {
          this.setting.left = this.fixed((this.setting.width - tempWidth) / 2);
        }
      },
      reflectHeightCm(v) {
        const { height } = this.getMosaicSize;
        const tempHeight = Number((v * IMAGE_TO_CANVAS_RATIO * PHOTO_HEIGHT_RATIO).toFixed(2));
        if (this.setting.height < tempHeight) {
          this.setting.height = this.fixed(tempHeight);
          this.setting.top = 0;
        } else {
          this.setting.top = this.fixed((this.setting.height - tempHeight) / 2);
        }
      },

      reflectColumns() {
        const { columns, width } = this.getMosaicSize;
        this.setting.cols = columns;
        this.setting.left = this.fixed((this.setting.width - width) / 2);
      },
      reflectRows() {
        const { rows, height } = this.getMosaicSize;
        this.setting.rows = rows;
        this.setting.top = this.fixed((this.setting.height - height) / 2);
      },
      saveMosaicImageURL() {
        this.settingPanel.mosaicImageURL = this.$fileToUrl(this.setting.mosaicImage);
      },
      saveMosaic() {
        if (this.setting.mosaicImage instanceof File) {
          return this.$toastShow({
            type: 'error',
            title: 'Need save',
            content: `Need save`
          })
        }
        // console.log(this.$parent, this.project_uid, this.settings_uid, this.settingPanel.mosaicImageURL);
        axios.post(`${ config.MOSAIC_API_URL }/init`, 
          JSON.stringify({
            project_uid: this.project_uid,
            settings_uid: this.settings_uid,
            rows: this.setting.rows,
            columns: this.setting.cols,
            width: this.setting.width,
            height: this.setting.height,
            top: this.setting.top,
            left: this.setting.left,
            url: this.setting.mosaicImage
          }),
          {
              headers: {
                  'Content-Type': 'application/json',
              }
          }
        )
          .then(d => {
            console.log('save success', d.data, d.data.result);
            if (d.data.result) {
              this.$toastShow({
                title: 'Success save mosaic data',
                content: `Success save mosaic data`
              });
            } else {
              this.$toastShow({
                type: 'error',
                title: 'Fail save mosaic data',
                content: `Fail save mosaic data : ${ d.data.context }`
              });
            }
          }, d => {
            console.log('save fail')
            this.$toastShow({
              type: 'error',
              title: 'Fail save mosaic data',
              content: `Fail save mosaic data`
            });
          });
      },
      useMosaic(v) {
        // console.log(v);
        this.$parent.printerSetting.template.type = v ? 'double' : 'single';
        return this.setting.use;
      }
    },
    computed: {
      widthPaddingMax() {
        const tempWidth = Number((this.setting.cols * IMAGE_TO_CANVAS_RATIO * PHOTO_WIDTH_RATIO));
        return this.fixed(this.setting.width - tempWidth);
      },
      heightPaddingMax() {
        const tempHeight = Number((this.setting.rows * IMAGE_TO_CANVAS_RATIO * PHOTO_HEIGHT_RATIO));
        return this.fixed(this.setting.height - tempHeight);
      },
      setting() {
        return this.$parent.printerSetting[this.$parent.currentTab];
      },
      settingPanel() {
        return this.$parent.settingPanel;
      },

      placeholderWidth() {
        return this.setting.width;
      },
      placeholderHeight() {
        return this.setting.height;
      },
      getMosaicSize() {
        const itc = IMAGE_TO_CANVAS_RATIO;
        const columns = Math.floor(this.setting.width / (PHOTO_WIDTH_RATIO * itc));
        const rows = Math.floor(this.setting.height / (PHOTO_HEIGHT_RATIO * itc));
        return { columns,
          rows,
          width: columns * itc * PHOTO_WIDTH_RATIO,
          height: rows * itc * PHOTO_HEIGHT_RATIO
        };
      },
      getMosaicImage() {
        return this.setting.mosaicImage instanceof File ? this.settingPanel.mosaicImageURL
                                                        : this.setting.mosaicImage;
      },
      getMosaicFileName() {
        return this.setting.mosaicImage instanceof File ? this.setting.mosaicImage.name 
                                                        : this.setting.mosaicImage.split('/').slice(-1);
      },
      project_uid() {
        return this.$parent.projectUid;
      },
      settings_uid() {
        return this.$parent.settingsUid;
      }
    },
  };
</script>

<style lang='scss' scoped>
  dt, dd {
    margin-bottom: 20px;
    display: inline-block;
    vertical-align: middle;
  }
  dt {
    min-width: 100px;
    width: 30%;
  }
  dd {
    width: 60%;
  }
</style>