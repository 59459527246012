<template>
  <div v-show="currentTab === 'custom'">
    <preview-piece
          :background="setting.backgroundImage" :setting-panel="settingPanel"></preview-piece>
  </div>
</template>

<script>
import previewPiece from './preview-piece.vue';
export default {
  props: ['currentTab', 'setting', 'setting-panel'],
  components: {
    previewPiece
  }
}
</script>

<style lang="scss" scoped>

</style>

