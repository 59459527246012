export default new class {
  constructor() {
    this.s = 1000;
    this.m = 60 * 1000;
    this.h = 60 * 60 * 1000;
    this.d = 24 * 60 * 60 * 1000;
  }
  current(v = new Date().getTime()) {
    const d = new Date(v);
    const YYYYMMDD = [d.getFullYear(), d.getMonth() + 1, d.getDate()].map(item => String(item).length > 2 ? item : (String(item).length === 1 ? '0' + item : item)).join('-');
    const HMS = [d.getHours(), d.getMinutes(), d.getSeconds()].map(item => String(item).length > 2 ? item : (String(item).length === 1 ? '0' + item : item)).join(':');
    return [YYYYMMDD, HMS].join(' ');
  }
  time(v = this.current()) {
    return new Date(v).getTime();
  }
};