<template>
	<div class='custom-select'>
		<div
      class='custom-select-main'
			@mousedown.prevent="() => {}"
      @click="toggleSelect">
			<span class='custom-selected'>{{ value }}</span>
			<i class='material-icons'>&#xe146;</i>
		</div>
		<transition name="fade-custom-select">
			<div class='custom-options'
				v-show='isOpen'>
				<div
					class="custom-option"
					v-for="(option, index) of options"
					:class="{ selected: value === option }"
					@mousedown.prevent="() => {}"
					@click="selectOption(option, index)"
					:key="index">{{ option }}</div>
			</div>
		</transition>
	</div>
</template>

<script>
	export default {
    model: {
      event: 'change'
    },
		props: {
      options: Array,
      selected: {
        type: Number,
        default: 0
      },
      value: String
    },
		data() {
			return {
				isOpen: false,
				selectedIndex: this.selected
			};
		},
		methods: {
			toggleSelect() {
				this.isOpen = !this.isOpen;
			},
			selectOption(option, index) {
				this.selectedIndex = index;
        this.$emit('selectOption', index);
				this.$emit('change', option);
        this.$emit('changed', option);				
				this.toggleSelect();
			},
			changeSelected() {

			}
		}
	}
</script>


<style lang='scss' scoped>
	@import url(https://fonts.googleapis.com/icon?family=Material+Icons);

	.custom-select {
		width: 100%;
		height: 30px;
		.fade-custom-select-enter-active,
		.fade-custom-select-leave-active {
			transition: opacity .2s, transform .2s;
			transform-origin: top;
		}
		.fade-custom-select-enter,
		.fade-custom-select-leave-to/* .fade-leave-active below version 2.1.8 */
			{
			transform: scaleY(0);
			opacity: 0;
		}
	}

	.custom-select-main {
		cursor: pointer;
		width: 100%;
		position: relative;
		line-height: 1.2em;
		border: 1px solid #FF8400;
		padding: 5px;
		box-sizing: border-box;
		border-radius: 5px;
		background: white;
		&:after {
			content: '';
		}
		&:hover {
			border: 1px solid #FF8400;
		}
		i {
			position: absolute;
			top: 0;
			right: 0;
			color: #FF8400;
			font-size: 30px;
		}
	}

	.custom-options {
    cursor: pointer;
		position: relative;
		width: 100%;
		background: #f6f6f6;
		padding: 5px;
		box-sizing: border-box;
		z-index: 999;
		.custom-option {
			height: 1em;
			line-height: 1em;
			padding: 7px 0;
			border-bottom: 1px solid #e5e5e5;
			&:last-child {
				border-bottom: 0;
			}
			&:hover {
				background: #f6f6f6;
			}
			&.selected {
				color: #FF8400;
				font-weight: bold;
			}
		}
	}

</style>
