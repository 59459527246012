<template>
  <div class='setting-panel' v-bar>
    <div class='setting-contents'>
      <div class='setting-body'>
        <p>Intro is full imaged page, this page is like a screen saver, and will be disappeared by touching the screen.</p>
        <div class='hr'></div>
        <hs-checkbox v-model="setting.skip">Skip this page.</hs-checkbox>
        <!-- <p>Duplicate settings.</p> -->
        <!-- <hs-select :options="['None', 'Kiosk 1']" v-model="setting.copy"></hs-select>   -->
        
      </div>
      <template v-if="!setting.skip">
        <div class='setting-title'>
          Layout
        </div>
        <div class='setting-body'>
          <p>Upload Image</p>
          <hs-file
            style='width: 100%;'
            v-model="setting.backgroundImage"
            :guide="{ width: 1080, height: 1920 }"></hs-file>
        </div>
        <div>
          <hs-checkbox
            v-model="useMainBackground"
            @change="checkMainBackground(useMainBackground)">Use background image like main</hs-checkbox>
        </div>
        <div class='setting-title'>
          Timing
        </div>
        <div class='setting-body'>
          <p>Show intro when peopel don't use this kiosk for</p>
          <hs-number v-model="setting.inTime" :maxlength="4" :min="30" :max="1200">sec</hs-number>
          <p>Transfer to Main page after</p>
          <hs-number v-model="setting.outTime" :maxlength="4" :min="30" :max="1200">sec</hs-number>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        useMainBackground: false
      }
    },
    methods: {
      checkMainBackground(bool) {
        if (bool) {
          this.$background = this.setting.backgroundImage;
          this.setting.backgroundImage = this.$parent.printerSetting['postView'].backgroundImage;
        }
        else {
          this.setting.backgroundImage = this.$background;
        }
      }
    },
    computed: {
      setting() {
        return this.$parent.printerSetting[this.$parent.currentTab];
      },
    }
  };
</script>

<style>
</style>