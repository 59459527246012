<template>
  <div 
    class='mosaic-controller-background'
    v-show="visible">
    <canvas
      class='mosaic-controller'
      ref="mosaic"
      @mousedown.prevent="mosaicController.tab"
      @wheel.prevent="e => mosaicController.wheel(e)">
      <span
        :init='watchVisible'></span>
      </canvas>
    <div class='menu-items'>
      <button class='back'
        @click="close"></button>
      <button class='reload'
        @click="e => reload()"></button>
      <div class='menu-panel'>
        <button
          @click="e => mosaicController.setCenter(e)">Center</button>
        <button
          @click="e => mosaicController.fitCenter()">Fit</button>
        <button
          @click="e => mosaicController.zoomIn(e)">Zoom In</button>
        <button
          @click="e => mosaicController.zoomOut(e)">Zoom Out</button>

        <div v-show="selectedCellIndex !== -1">
          {{ selectedCellData.number }}
          <button
            @click="e => markCell(e)">Mark Cell</button>
          <button
            @click="e => clearCell(e)">Clear Cell</button>
          <button
            v-show="!selectedCellData.queue"
            @click="e => queue(e)">Queue Cell</button>
          <button
            v-show="selectedCellData.queue"
            @click="e => clearQueue(e)">Clear Queue Cell</button>
        </div>
        <p>grid color :
          <input type='text'
            :value="gridColor"
            @input="inputColor($event, 'grid')" /></p>
        <p>highlight color : 
          <input type='text'
            :value="highlightColor"
            @input="inputColor($event, 'highlight')" /></p>
        <p>queue color :
          <input type='text'
            :value="queueColor"
            @input="inputColor($event, 'queue')" /></p>
        <p>selected color :
          <input type='text'
            :value="selectedColor"
            @input="inputColor($event, 'selected')" /></p>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import eventBus from '../lib/event-bus';
import config from '../config';
import mosaicController from '../lib/mosaic-controller';

document.addEventListener('mousemove', e => mosaicController.move(e));
document.addEventListener('mouseup', e => mosaicController.release(e))

export default {
  props: ['visible', 'setting-mosaic', 'setting-panel', 'project-uid', 'settings-uid'],
  data() {
    return {
      selectedCellIndex: -1,
      selectedCellData: {},
      gridColor: 'black',
      highlightColor: 'rgba(255,255,255,0.5)',
      queueColor: 'rgb(150, 250, 150)',
      selectedColor: 'rgb(0,0,255)',
    }
  },
  created() {
    console.log(this);
    eventBus.$on('select-cell', data => {
      this.selectedCellIndex = -1;
      this.selectedCellData = {};
      if (data === -1) {
      }
      else {
        // console.log(mosaicController.__lastData);
        this.selectedCellIndex = data.index;
        this.selectedCellData.number = data.number;
        if (mosaicController.__lastData.map[data.index]) this.selectedCellData.printed = true;
        if (mosaicController.__lastData.queue.indexOf(data.index) !== -1) this.selectedCellData.queue = true;
        // console.log(this.selectedCellData.queue);
      }
    });

    eventBus.$on('close-controller', _=> {
      this.close();
    });

    eventBus.$on('block-off', _=> {
      this.$blockOff();
    });
  },
  methods: {
    initialize() {
      const img = new Image();
      img.onload = _=> {
        const { width, height, top, left, rows, cols } = this.settingMosaic
        mosaicController.init(this.$refs.mosaic, img, width, height, top, left, rows, cols, this.projectUid, this.settingsUid, this.$toastShow);
      };
      
      img.src = this.settingMosaic.mosaicImage instanceof File ? this.settingPanel.mosaicImageURL
                                                               : this.settingMosaic.mosaicImage;
    },
    close() {
      mosaicController.close();
      this.settingPanel.onMosaicControl = false;
    },
    test(e) {
      // console.log(e)
    },
    reload() {
      this.$blockOn({
        text:'LOADING',
        delay: 3000,
        color: 'orange',
        sync: true,
      });
      mosaicController.load(_=> {
        this.$blockOff();
      })
    },
    markCell() {
      this.$blockOn({
        text:'Saving',
        delay: 3000,
        color: 'orange',
        sync: true,
      });
      axios.post(`${ config.MOSAIC_API_URL }/mark-cell`,
        JSON.stringify({
          project_uid: this.projectUid,
          settings_uid: this.settingsUid,
          cell_index: this.selectedCellIndex
        }),
        {
          headers: {
            'Content-Type': 'application/json',
          }
        }
      )
        .then(res => {
          if (res.data.result !== undefined) {
            mosaicController.load(_=> {
              this.$blockOff();
            });
          } else {
            this.$toastShow({
              type: 'error',
              title: 'Already queue line',
              content: `${ res.data.context }`
            });
            this.$blockOff();
          }
        })
    },
    clearCell() {
      this.$blockOn({
        text:'Saving',
        delay: 3000,
        color: 'orange',
        sync: true,
      });
      axios.post(`${ config.MOSAIC_API_URL }/clear-cell`,
        JSON.stringify({
          project_uid: this.projectUid,
          settings_uid: this.settingsUid,
          cell_index: this.selectedCellIndex
        }),
        {
          headers: {
            'Content-Type': 'application/json',
          }
        }
      )
        .then(res => {
          if (res.data.result !== undefined) {
            mosaicController.load(_=> {
              this.$blockOff();
            });
          }
        })
    },
    queue() {
      this.$blockOn({
        text:'Saving',
        delay: 3000,
        color: 'orange',
        sync: true,
      });
      axios.post(`${ config.MOSAIC_API_URL }/queue`,
        JSON.stringify({
          project_uid: this.projectUid,
          settings_uid: this.settingsUid,
          cell_index: this.selectedCellIndex
        }),
        {
          headers: {
            'Content-Type': 'application/json',
          }
        }
      )
        .then(res => {
          console.log(res.data.result);
          if (res.data.result) {
            mosaicController.load(_=> {
              this.$blockOff();
            });
          } else {
            this.$toastShow({
              type: 'error',
              title: 'Already printed',
              content: `${ res.data.context }`
            });
            this.$blockOff();
          }
        })
    },
    clearQueue() {
      this.$blockOn({
        text:'Saving',
        delay: 3000,
        color: 'orange',
        sync: true,
      });
      axios.post(`${ config.MOSAIC_API_URL }/clear-queue`,
        JSON.stringify({
          project_uid: this.projectUid,
          settings_uid: this.settingsUid,
          cell_index: this.selectedCellIndex
        }),
        {
          headers: {
            'Content-Type': 'application/json',
          }
        }
      )
        .then(res => {
          console.log(res.data.result);
          if (res.data.result) {
            mosaicController.load(_=> {
              this.$blockOff();
            });
          } else {
            // this.$toastShow({
            //   type: 'error',
            //   title: 'Already printed',
            //   content: `${ res.data.context }`
            // });
            this.$blockOff();
          }
        })
    },
    inputColor(e, type) {
      console.log('test');
      const { value } = e.target;
      this[`${ type }Color`] = value;
      eventBus.$emit(`input-color`, { type, value });
    }
  },
  computed: {
    watchVisible() {
      if (this.visible) this.initialize();
      return this.visible;
    },
    mosaicController() {
      return mosaicController;
    }
  }
}
</script>

<style lang='scss'>
.mosaic-controller-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 39;
  background: black;
}
.mosaic-controller {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 40;
}
.menu-items {
  .back {
    position: absolute;
    width: 50px;
    height: 50px;
    top: 0;
    left: 0;
    z-index: 41;
  }
  .reload {
    position: absolute;
    width: 50px;
    height: 50px;
    top: 0;
    right: 0;
    z-index: 41;
  }
  .menu-panel {
    position: absolute;
    width: 600px;
    height: 150px;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background: rgba(255, 255, 255, 0.75);
    z-index: 41;
  }
}
</style>
