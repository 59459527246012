export default {
  loadImg(src, callback) {
    var img = new Image();
    img.onload = function () {
      if (typeof callback === 'function') callback(null, img);
    };
    img.onerror = function (e) {
      if (typeof callback === 'function') callback(e, true);
    };
    img.src = src;
  },
  setSize(canvas, w, h) {
    canvas.width = w;
    canvas.height = h;
  },
  fitCanvas(canvas, img) {
    canvas.width = img.width;
    canvas.height = img.height;
  },
  keep(ctx, x, y, r, callback) {
    ctx.save();
    ctx.translate(x, y);
    ctx.rotate(r);
    callback();
    ctx.restore();
  },
  mDrawImage(ctx, img) {
    ctx.drawImage(img, - img.width / 2, - img.height / 2);
  },
  sameRatioHeight(img, width) {
    if (!img.$aspectRatio) img.$aspectRatio = img.width / img.height;
    return width / img.$aspectRatio;
  },
  sameRatioWidth(img, height) {
    if (!img.$aspectRatio) img.$aspectRatio = img.width / img.height;
    return height * img.$aspectRatio;
  },
  clear(canvas) {
    canvas.getContext('2d').clearRect(0, 0, canvas.width, canvas.height);
  },
  drawCrop(ctx, img, x, y, w, h, offsetX = 0.5, offsetY = 0.5) {
    if (arguments.length === 2) {
        x = y = 0;
        w = ctx.canvas.width;
        h = ctx.canvas.height;
    }

    offsetX = typeof offsetX === "number" ? offsetX : 0.5;
    offsetY = typeof offsetY === "number" ? offsetY : 0.5;

    if (offsetX < 0) offsetX = 0;
    if (offsetY < 0) offsetY = 0;
    if (offsetX > 1) offsetX = 1;
    if (offsetY > 1) offsetY = 1;

    var iw = img.width,
        ih = img.height,
        r = Math.min(w / iw, h / ih),
        nw = iw * r,
        nh = ih * r,
        cx, cy, cw, ch, ar = 1;

    if (nw < w) ar = w / nw;                             
    if (Math.abs(ar - 1) < 1e-14 && nh < h) ar = h / nh;
    nw *= ar;
    nh *= ar;

    cw = iw / (nw / w);
    ch = ih / (nh / h);

    cx = (iw - cw) * offsetX;
    cy = (ih - ch) * offsetY;

    if (cx < 0) cx = 0;
    if (cy < 0) cy = 0;
    if (cw > iw) cw = iw;
    if (ch > ih) ch = ih;

    ctx.drawImage(img, cx, cy, cw, ch,  x, y, w, h);
  }
};