<template>
  <div class='setting-panel' v-bar>
    <div class='setting-contents'>
      <div class='setting-body'>
        <p>이벤트는 포토 인화시 설정된 조건에 따라 당첨마크가 같이 인화되어 나오는 기능입니다.</p>
        <div class='hr'></div>
        <hs-checkbox v-model="setting.use">Use Event.</hs-checkbox>
      </div>
      <template v-if="setting.use">
      <div class='setting-title'>
        Setting
      </div>
      <div class='setting-body'>
        <div>
          <div>
            <p><button class='text-button' @click="addStamp()">Add Stamp</button></p>
            <div
              v-for="(prize, prize_index) of setting.prizes"
              :key="'prize' + prize_index">
              <hs-list-file v-model="setting.prizes[prize_index]" :change-button="true"></hs-list-file>
              <button class='detail-button' @click="setPrize(prize_index)"></button>
              <div v-show="showPrize(prize_index)">
                <p><b>Prize & Stamp</b></p>
                <div>
                  <dt>Width</dt>
                  <dd><hs-number v-model="setting.prizes[prize_index].w" :maxlength="4" :min="10" :max="1800">px</hs-number></dd>
                </div>
                <div>
                  <dt>Height</dt>
                  <dd><hs-number v-model="setting.prizes[prize_index].h" :maxlength="4" :min="10" :max="1800">px</hs-number></dd>
                </div>
                <div>
                  <dt><b>Stamp Rotation</b></dt>
                  <dd><hs-number v-model="setting.prizes[prize_index].r" :maxlength="3" :min="-360" :max="360">deg</hs-number></dd>
                </div>
                <div>
                  <p><b>Stamp Position</b></p>
                  <dt>Top</dt>
                  <dd><hs-number v-model="setting.prizes[prize_index].y" :maxlength="4" :min="0" :max="1800">px</hs-number></dd>
                  <dt>Left</dt>
                  <dd><hs-number v-model="setting.prizes[prize_index].x" :maxlength="4" :min="0" :max="1200">px</hs-number></dd>
                </div>
              </div>
              <button class='delete-button' @click="_=> setting.prizes.splice(prize_index, 1)"></button>
            </div>
          </div>
        </div>
      </div>
      </template>
    </div>
  </div>
</template>

<script>
import t from '../lib/time';
export default {
  // props: ['setting-panel'],
  data() {
    return {
      currentEventIndex: -1,
      currentPrizeIndex: -1,
    }
  },
  methods: {
    // addEvent() {
    //   const d = new Date();
    //   const YYYYMMDD = [d.getFullYear(), d.getMonth() + 1, d.getDate()].map(item => String(item).length > 2 ? item : (String(item).length === 1 ? '0' + item : item)).join('-');
    //   const HMS = [d.getHours(), d.getMinutes(), d.getSeconds()].map(item => String(item).length > 2 ? item : (String(item).length === 1 ? '0' + item : item)).join(':');
      
    //   this.setting.events.push({
    //     "id": this.setting.last_id + 1,
    //     "name": 'event_1',
    //     "startTime": t.current(),
    //     "endTime": t.current(t.time() + t.d),
    //     "prizes": [{
    //       "name": 'prize_',
    //       "url": 'https://hashsnap-reports.s3.ap-northeast-2.amazonaws.com/uploaded-posts/724d5dd0-3b5c-49ed-96de-a4be98a372fa.png',
    //       "x": 0,
    //       "y": 0,
    //       "w": 150,
    //       "h": 150,
    //       "r": 0
    //     }]
    //   });
    //   this.setting.last_id++;
    // },
    addStamp() {
      this.setting.prizes.push({
        "name": 'prize_',
        "url": 'https://hashsnap-reports.s3.ap-northeast-2.amazonaws.com/uploaded-posts/724d5dd0-3b5c-49ed-96de-a4be98a372fa.png',
        "x": 0,
        "y": 0,
        "w": 150,
        "h": 150,
        "r": 0
      });
    },
    setPrize(prize_index) {
      if (this.settingPanel.eventPrizeIndex === prize_index) {
        this.settingPanel.eventPrizeIndex = -1;
      }
      else {
        this.settingPanel.eventPrizeIndex = prize_index;
      }
    },
    showPrize(prize_index) {
      if (this.settingPanel.eventPrizeIndex === prize_index) return true;
      return false;
    },
    // removeEvent(index) {
    //   this.setting.events.splice(index, 1);
    // },
    checkStartDate(index) {
      const currentEvent = this.setting.events[index];
      if ((new Date(currentEvent.startDate)).getTime() > (new Date(currentEvent.endDate)).getTime()) {
        currentEvent.startDate = currentEvent.endDate;
      }
    },
    checkEndDate(index) {
      const currentEvent = this.setting.events[index];
      if ((new Date(currentEvent.startDate)).getTime() > (new Date(currentEvent.endDate)).getTime()) {
        currentEvent.endDate = currentEvent.startDate;
      }
    }
  },
  computed: {
    setting() {
      return this.$parent.printerSetting[this.$parent.currentTab];
    },
    settingPanel() {
      return this.$parent.settingPanel;
    }
  },
};
</script>

<style lang='scss' scoped>
  .text-button {
    cursor: pointer;
    height: 27px;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 5px;
    box-sizing: border-box;
    background: white;
    vertical-align: middle;
  }
  .detail-button, .delete-button {
    cursor: pointer;
    width: 27px;
    height: 27px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background: white;
    vertical-align: middle;
  }
  .detail-button {
    background: url(/../../assets/icon/baseline-edit-24px.svg);
  }
  .delete-button {
    background: url(/../../assets/icon/baseline-delete-24px.svg);
  }
  .detail-panel {
    // display: none;
    background: #f1f1f1;
    padding: 1px 20px;
    border-radius: 5px;
    box-sizing: border-box;
    p {
      margin: 10px 0;
      vertical-align: middle;
      > * {
        vertical-align: middle;
      }
    }
    dt, dd {
      margin-bottom: 10px;
      display: inline-block;
      vertical-align: middle;
    }
    dt {
      min-width: 70px;
      width: 40%;
    }
    dd {
      width: 55%;
    }
  }
  dt, dd {
    margin-bottom: 20px;
    display: inline-block;
    vertical-align: middle;
  }
  dt {
    min-width: 100px;
    width: 30%;
  }
  dd {
    width: 60%;
  }
</style>