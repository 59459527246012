<template>
  <div
    class='piece'
    :style="computedStyle">
      <slot :ratio="ratio"></slot>
  </div>
</template>

<script>
  export default {
    props: {
      background: {
        default: ''
      },
      width: {
        default: 1080
      },
      height: {
        default: 1920
      },
      top: {
        default: false
      },
      bottom: {
        default: false
      },
      left: {
        default: false
      },
      right: {
        default: false
      },
      zIndex: {
        default: 1
      },
      'setting-panel': {
        default: {}
      }
    },
    data() {
      return {
        // standardHeight: 850
      }
    },
    methods: {
      computeLength(v) {
        if (v === false) {
          return false;
        } else {
          return v * this.ratio + 'px';
        }
      }
    },
    computed: {
      standardHeight() {
        return this.settingPanel.previewHeight;
      },
      ratio() {
        return this.standardHeight / 1920;
      },
      computedWidth() {
        return this.computeLength(this.width);
      },
      computedHeight() {
        return this.computeLength(this.height);
      },
      computedTop() {
        return this.computeLength(this.top);
      },
      computedBottom() {
        return this.computeLength(this.bottom);
      },
      computedLeft() {
        return this.computeLength(this.left);
      },
      computedRight() {
        return this.computeLength(this.right);
      },
      computedBackgroundPosition() {
        // console.log('this.bottom', this.bottom, this.height)
        if (this.top !== false) {
          return `${ Number(this.top) * this.ratio }px`;
        }
        if (this.bottom !== false) {
          return `${ -(1920 - Number(this.height) - Number(this.bottom)) * this.ratio }px`;
        }
      },
      computedStyle() {
        return {
          'background-image': this.$toBgImg(this.background),
          width : this.computedWidth,
          height: this.computedHeight,
          top: this.computedTop,
          bottom: this.computedBottom,
          left: this.computedLeft,
          right: this.computedRight,
          'z-index': this.zIndex,
          'background-position-y': this.computedBackgroundPosition
        };
      }
    }
  }
</script>

<style lang='scss' scoped>
  .piece {
    position: absolute;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    // transition: height 0.3s;
  }
</style>