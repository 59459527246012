<template>
  <div>
    <label
      :for="id"
      :class="{ checked }"
      >
      <div class='box'></div>
      <input
        type='checkbox'
        :id="id"
        :checked="checked"
        @change="syncValue"
        />
      <span><slot></slot></span>
    </label>
  </div>
</template>

<script>
export default {
  model: {
    prop: 'checked',
    event: 'change'
  },
  props: {
    id: {
      type: String,
      default: function () {
        return `y-checkbox-${ this._uid }`;
      }
    },
    checked: Boolean,
    value: String
  },
  methods: {
    syncValue() {
      // console.log('checked', this.checked)
      this.$emit('change', !this.checked);
    }
  },
}
</script>


<style lang="scss" scoped>
  input[type="checkbox"] {
    display: none;
  }
  label {
    display: inline-block;
  }
  span {
    vertical-align: middle;
  }
  label:hover .box {
    background: #f5f5f5;
  }
  .box {
    display: inline-flex;
    width: 15px;
    height: 15px;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
    border: 1px solid #bbb;
    border-radius: 3px;
    background: white;
  }
  .checked .box:before {
    content: '';
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 2px;
    background: #bbb;
  }
  
</style>

