import eventBus from '../lib/event-bus';
import c from '../lib/canvas-tools';
import config from '../config';
import axios from 'axios';
import { PHOTO_WIDTH, PHOTO_HEIGHT } from '../default.setting';

window.addEventListener("resize", _=> mosaicController.draw());
const alphaNums = n => {
  if (n === 0) return;
  const nums = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  let total = n;
  let result = '';
  do {
    let r = (total - 1) % 26;
    result = nums[r] + result;
  } while (total = Math.floor((total - 1) / 26))
  return result;
}

const originalImgCanvas = document.createElement('canvas');
const originalImgCtx = originalImgCanvas.getContext('2d');
const DPR = devicePixelRatio;
const CTP = 28.3464 * 0.25;
let $canvas;
let $ctx;
let $mosaicImage;
let $mosaicWidth;
let $mosaicHeight;
let $mosaicTop;
let $mosaicLeft;
let $rows;
let $columns;
let $zoomRatio;
let $cx = 0;
let $cy = 0;

let $highlightX = -1;
let $highlightY = -1;

let isPushed = false;
let ox;
let oy;

let isOpen = false;

let $projectUid = '';
let $settingsUid = '';

let $toastShow;

let $gridColor = 'black';
let $highlightColor = 'rgba(255,255,255,0.5)';
let $queueColor = 'rgb(150, 250, 150)';
let $selectedColor = 'rgb(0,0,255)';

let $mosaicData;

eventBus.$on('input-color', data => {
  const { type, value } = data;
  if (type === 'grid') $gridColor = value;
  if (type === 'highlight') $highlightColor = value;
  if (type === 'queue') $queueColor = value;
  if (type === 'selected') $selectedColor = value;

  mosaicController.drawOriginImage();
  mosaicController.draw();
})



const mosaicController = new class {
  init(canvasEl, img, mosaicWidth, mosaicHeight, mosaicTop, mosaicLeft, rows, columns, projectUid, settingsUid, toast) {
    document.body.style.overflow = 'hidden';
    $canvas = canvasEl;
    $ctx = $canvas.getContext('2d');
    c.setSize($canvas, window.innerWidth * DPR, window.innerHeight * DPR);

    $cx = 0;
    $cy = 0;
    $mosaicImage = img;

    $mosaicWidth = mosaicWidth;
    $mosaicHeight = mosaicHeight;
    $mosaicTop = mosaicTop;
    $mosaicLeft = mosaicLeft;
    $rows = rows;
    $columns = columns;

    if (!$projectUid) {
      $projectUid = projectUid;
      $settingsUid = settingsUid;
    }
    if (!$toastShow) {
      $toastShow = toast;
    }
    this.fitCenter();
    this.load();    
  }
  load(callback = _=> {}) {
    axios.get(`${config.MOSAIC_API_URL}/map?project_uid=${ $projectUid }&settings_uid=${ $settingsUid }`)
      .then(res => {
        $mosaicData = res.data;
        this.__lastData = res.data;
        if (
          $mosaicWidth === res.data.width &&
          $mosaicHeight === res.data.height &&
          $mosaicTop === res.data.top &&
          $mosaicLeft === res.data.left &&
          $rows === res.data.rows &&
          $columns === res.data.columns
        ) {
          this.drawOriginImage(res.data);
          isOpen = true;
          callback();
        } else {
          $toastShow({
            type: 'error',
            title: 'No matching',
            content: `Save Mosaic and initialize. (데이터가 초기화됩니다.) or 되돌리기`
          });
          eventBus.$emit('close-controller');
        }
        eventBus.$emit('block-off');
      });
  }
  draw() {
    if ($mosaicImage) this.drawMosaicImage();

  }
  drawOriginImage() {
    c.setSize(originalImgCanvas, $mosaicWidth * CTP, $mosaicHeight * CTP * 6);
    c.drawCrop(originalImgCtx, $mosaicImage, 0, 0, $mosaicWidth * CTP, $mosaicHeight * CTP);

    const photoWidthPx = PHOTO_WIDTH * CTP;
    const photoHeightPx = PHOTO_HEIGHT * CTP;

    const lineWidth = photoWidthPx * $columns;
    const lineHeight = photoHeightPx * $rows;
    const left = $mosaicLeft * CTP;
    const top = $mosaicTop * CTP;

    c.keep(originalImgCtx, 0, this.originHeight * 3, 0, _=> {
      originalImgCtx.beginPath();

      for (let row_index = 0; row_index <= $rows; row_index++) {
        originalImgCtx.moveTo(left, top + photoHeightPx * row_index);
        originalImgCtx.lineTo(left + lineWidth, top + photoHeightPx * row_index);
      }
      for (let column_index = 0; column_index <= $columns; column_index++) {
        originalImgCtx.moveTo(left + photoWidthPx * column_index, top);
        originalImgCtx.lineTo(left + photoWidthPx * column_index, top + lineHeight);
      }
      originalImgCtx.strokeStyle = $gridColor;
      originalImgCtx.stroke();
      originalImgCtx.closePath();

      originalImgCtx.font = `15px NanumGothic`;
      originalImgCtx.textAlign = "center";
      originalImgCtx.fillStyle = $gridColor;
      for(let i = 0; i < $columns; i++) {
        for(let j = 0; j < $rows; j++) {          
          originalImgCtx.fillText(alphaNums(j + 1) + (i + 1), left + photoWidthPx * i + photoWidthPx / 2, top + photoHeightPx * j + photoHeightPx / 2);
        }
      }
      // window.axios = axios;
    });
    c.keep(originalImgCtx, 0, this.originHeight * 1, 0, _=> {
      $mosaicData.map.forEach((item, index) => {
        if (item === 0) {
          const row = Math.floor(index / $columns);
          const column = index % $columns;
          originalImgCtx.fillStyle = $highlightColor;
          originalImgCtx.fillRect(left + photoWidthPx * column, top + photoHeightPx * row, photoWidthPx, photoHeightPx);
        }
      });
    });
    c.keep(originalImgCtx, 0, this.originHeight * 4, 0, _=> {
      $mosaicData.queue.forEach((map_index) => {
        const row = Math.floor(map_index / $columns);
        const column = map_index % $columns;
        const gradient = originalImgCtx.createLinearGradient(left + photoWidthPx * column, top + photoHeightPx * row, photoWidthPx, photoHeightPx);
        originalImgCtx.strokeStyle = $queueColor;
        originalImgCtx.lineWidth=3;
        originalImgCtx.strokeRect(left + photoWidthPx * column, top + photoHeightPx * row, photoWidthPx, photoHeightPx);
      });
    });
  }
  drawOriginHighlight(e) { // 2
    const clientX = e.clientX * DPR;
    const clientY = e.clientY * DPR;
    const originalRect = this.getOriginRect();
    const originalRectHalfWidth = originalRect.width / 2;
    const originalRectHalfHeight = originalRect.height / 2;
    const sx = $canvas.width / 2 + $cx - originalRectHalfWidth;
    const ex = $canvas.width / 2 + $cx + originalRectHalfWidth;
    const sy = $canvas.height / 2 + $cy - originalRectHalfHeight;
    const ey = $canvas.height / 2+ $cy + originalRectHalfHeight;

    // console.log(sx, clientX, ex);
    // console.log(sy, clientY, ey);
    if (sx < clientX && clientX < ex &&
        sy < clientY && clientY < ey) {
      const posX = (clientX - sx) / $zoomRatio;
      const posY = (clientY - sy) / $zoomRatio;
      // console.log('in');
      const photoWidthPx = PHOTO_WIDTH * CTP;
      const photoHeightPx = PHOTO_HEIGHT * CTP;
      const left = $mosaicLeft * CTP;
      const top = $mosaicTop * CTP;
      
      const nextHighlightX = Math.floor((posX - left) / photoWidthPx);
      const nextHighlightY = Math.floor((posY - top) / photoHeightPx);
      
      if ($highlightX === nextHighlightX &&
          $highlightY === nextHighlightY) return;
      $highlightX = nextHighlightX;
      $highlightY = nextHighlightY;
        
      // console.log($highlightX, $highlightY);
      if (!(-1 < $highlightX && $highlightX < $columns &&
          -1 < $highlightY && $highlightY < $rows)) return;
      c.keep(originalImgCtx, 0, this.originHeight * 2, 0, _=> {
        originalImgCtx.clearRect(0, 0, this.originWidth, this.originHeight);
        originalImgCtx.fillStyle = 'rgba(255,255,255, 0.5)';
        originalImgCtx.fillRect(
          left + photoWidthPx * $highlightX,
          top + photoHeightPx * $highlightY,
          photoWidthPx,
          photoHeightPx
        );
      });
      
    } else {
      $highlightX = -1;
      $highlightY = -1;
      c.keep(originalImgCtx, 0, this.originHeight * 2, 0, _=> {
        originalImgCtx.clearRect(0, 0, this.originWidth, this.originHeight);
      });
    }
  }
  drawOriginStroke(e) {
    const clientX = e.clientX * DPR;
    const clientY = e.clientY * DPR;
    const originalRect = this.getOriginRect();
    const originalRectHalfWidth = originalRect.width / 2;
    const originalRectHalfHeight = originalRect.height / 2;
    const sx = $canvas.width / 2 + $cx - originalRectHalfWidth;
    const ex = $canvas.width / 2 + $cx + originalRectHalfWidth;
    const sy = $canvas.height / 2 + $cy - originalRectHalfHeight;
    const ey = $canvas.height / 2+ $cy + originalRectHalfHeight;
    if (sx < clientX && clientX < ex &&
      sy < clientY && clientY < ey) {
      const posX = (clientX - sx) / $zoomRatio;
      const posY = (clientY - sy) / $zoomRatio;

      const photoWidthPx = PHOTO_WIDTH * CTP;
      const photoHeightPx = PHOTO_HEIGHT * CTP;
      const left = $mosaicLeft * CTP;
      const top = $mosaicTop * CTP;
      
      const strokeX = Math.floor((posX - left) / photoWidthPx);
      const strokeY = Math.floor((posY - top) / photoHeightPx);
      
      if (!(-1 < strokeX && strokeX < $columns &&
          -1 < strokeY && strokeY < $rows)) {
        return c.keep(originalImgCtx, 0, this.originHeight * 5, 0, _=> {
          originalImgCtx.clearRect(0, 0, this.originWidth, this.originHeight);
        });
      };

      eventBus.$emit('select-cell', { index: strokeX + strokeY * $columns, number: alphaNums(strokeY + 1) + (strokeX + 1) });
      c.keep(originalImgCtx, 0, this.originHeight * 5, 0, _=> {
        originalImgCtx.beginPath();
        originalImgCtx.clearRect(0, 0, this.originWidth, this.originHeight);
        originalImgCtx.strokeStyle = $selectedColor;
        originalImgCtx.lineWidth=5;
        originalImgCtx.strokeRect(
          left + photoWidthPx * strokeX,
          top + photoHeightPx * strokeY,
          photoWidthPx,
          photoHeightPx
        );
        originalImgCtx.closePath();
      });
      
    } else {
      c.keep(originalImgCtx, 0, this.originHeight * 5, 0, _=> {
        originalImgCtx.clearRect(0, 0, this.originWidth, this.originHeight);
      });
      eventBus.$emit('select-cell', { index: -1 });
    }
    this.draw();
  }
  drawMosaicImage() {
    c.setSize($canvas, window.innerWidth * DPR, window.innerHeight * DPR);
    c.keep($ctx, $canvas.width / 2 + $cx, $canvas.height / 2 + $cy, 0, _=> {
      const zoomWidth = this.originWidth * $zoomRatio;
      const zoomHeight = this.originHeight * $zoomRatio;
      
      for (let i = 0; i < 6; i++) { 
        $ctx.drawImage(originalImgCanvas,
          0,
          this.originHeight * i,
          this.originWidth,
          this.originHeight,
          - zoomWidth / 2,
          - zoomHeight / 2,
          zoomWidth,
          zoomHeight
        );
      }
      // i = 0 : photo
      // i = 1 : cell highlight
      // i = 2 : cursor highlight
      // i = 3 : grid and text
      // i = 4 : select strock
    
    });
  }
  
  tab(e) {
    // console.log(e);
    const clientX = e.clientX * DPR;
    const clientY = e.clientY * DPR;
    isPushed = true;
    ox = clientX;
    oy = clientY;
    this.drawOriginStroke(e);
  }
  move(e) {
    if (isOpen) {
      this.drawOriginHighlight(e);
    }
    if (isPushed) {
      const clientX = e.clientX * DPR;
      const clientY = e.clientY * DPR;
      // console.log(e);
      const dx = clientX - ox;
      const dy = clientY - oy;
      $cx += dx;
      $cy += dy;
      ox = clientX;
      oy = clientY;
      
    }
    this.draw();
  }
  release(e) {
    isPushed = false;
    // console.log(e);
  }

  getOriginRect() {
    return { 
      width: this.originWidth * $zoomRatio,
      height: this.originHeight * $zoomRatio
    }
  }
  get originWidth() {
    return $mosaicWidth * CTP;
  }
  get originHeight() {
    return $mosaicHeight * CTP;
  }
  setCenter() {
    $cx = 0;
    $cy = 0;
    this.draw();
  }

  setZoomRatio() {
    const canvasAspectRatio = $canvas.width / $canvas.height;
    const originalAspectRatio = this.originWidth / this.originHeight;
    if (canvasAspectRatio < originalAspectRatio) $zoomRatio = $canvas.width / this.originWidth;
    else $zoomRatio = $canvas.height / this.originHeight;
  }
  fitCenter(v) {
    if (v) {
      $zoomRatio = v;
    }
    else {
      $cx = 0;
      $cy = 0;
      this.setZoomRatio();
    }
    this.draw();
  }
  zoomIn() {
    this.fitCenter($zoomRatio * 1.25);
  }
  zoomOut() {
    this.fitCenter($zoomRatio * 0.75);
  }
  wheel(e) {
    if (e.deltaY < 0) this.zoomIn();
    if (e.deltaY > 0) this.zoomOut();
  }

  close() {
    document.body.style.overflow = 'initial';
    isOpen = false;
  }
}

export default mosaicController;