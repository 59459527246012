<template>
  <div class='printer-setting'>
    <hs-mosaic-controller
      :visible="settingPanel.onMosaicControl"
      :setting-mosaic="printerSetting.mosaic"
      :setting-panel="settingPanel"
      :project-uid="project_uid"
      :settings-uid="printer_settings_uid"></hs-mosaic-controller>
    <hs-block></hs-block>
    <hs-toasted></hs-toasted>
    <tab-nav
      :current-tab="currentTab"
      :tab-flow="printerSetting.tabFlow"
      @moveTab="moveTab"></tab-nav>
    <tab-contents
      :current-tab="currentTab"
      :printer-setting="printerSetting"
      :setting-panel="settingPanel"
      :project-uid="project_uid"
      :settings-uid="printer_settings_uid"></tab-contents>
  </div>
</template>

<script>
window.onbeforeunload = confirmExit;
function confirmExit() {
  // return "";
  // return 1;
}
import "babel-polyfill";
import Vue from 'vue';
import tabNav from './layouts/tab-nav.vue';
import tabContents from './layouts/tab-contents.vue';

import hsCheckbox from './components/hs-checkbox.vue';
import hsFile from './components/hs-file.vue';
import hsListFile from './components/hs-list-file.vue';
import hsDropdown from './components/hs-dropdown.vue';
import hsNumber from './components/hs-number.vue';
import hsFloat from './components/hs-float.vue';
import hsInput from './components/hs-input.vue';
import hsSelect from './components/hs-select.vue';
import hsList from './components/hs-list.vue';
import hsCalendar from './components/hs-calendar.vue';
import hsColorSimple from './components/hs-color-simple.vue';
import hsEventList from './components/hs-event-list.vue';
import hsMosaicCanvas from './components/hs-mosaic-canvas.vue';
import hsMosaicController from './components/hs-mosaic-controller.vue';
import eventBus from './lib/event-bus';
import draggable from './lib/draggable';
import defaultSetting from './default.setting';
import config from './config';
import imgPreloader from './lib/img-preloader';

import Vuebar from 'vuebar';
import HSBlock from './plugins/hs-block';
import HSToasted from './plugins/hs-toasted';
import HSToBgImg from './plugins/hs-toBgImg';
import HSUtil from './plugins/hs-util';
import axios from 'axios';

Vue.use(Vuebar);
Vue.use(HSBlock);
Vue.use(HSToasted);
Vue.use(HSToBgImg);
Vue.use(HSUtil);
Vue.prototype.$eventBus = eventBus;
Vue.component('hsCheckbox', hsCheckbox);
Vue.component('hsFile', hsFile);
Vue.component('hsListFile', hsListFile);
Vue.component('hsDropdown', hsDropdown);
Vue.component('hsNumber', hsNumber);
Vue.component('hsFloat', hsFloat);
Vue.component('hsInput', hsInput);
Vue.component('hsSelect', hsSelect);
Vue.component('hsList', hsList);
Vue.component('hsCalendar', hsCalendar);
Vue.component('hsColorSimple', hsColorSimple);
Vue.component('hsEventList', hsEventList);
Vue.component('hsMosaicCanvas', hsMosaicCanvas);
Vue.component('hsMosaicController', hsMosaicController);

Vue.directive('drag', {
  bind: function (el, binding, vnode) {
    if (binding.value.draggable_move) el.draggable_move = (delta, e, el) => binding.value.draggable_move(delta, e, el);
    if (binding.value.draggable_up) el.draggable_up = (delta, e, el) => binding.value.draggable_up(delta, e, el);
    draggable.addElement(el);
  },
  unbind: draggable.removeElement
});


function uploadFile(file) {
  const formData = new FormData();
  formData.append('file', file);
  return axios.post(`${config.API_URL}/upload/file`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
  }).then(response => {
    console.log('File URL:', response.data);
    return Promise.resolve(response.data);
  });
}

const objectPath = (o, ...v) => {
  const path = v.slice(0);
  let cursor = o;
  while (path.length) {
    const name = path[0];
    if (cursor !== undefined) { cursor = cursor[name]; path.shift(); }
    else { break; }
  }
  return path.length ? [false, cursor] : [true, cursor];
};


export default {
  props: {
    project_uid: String,
    printer_settings_uid: String
  },
  data() {
    return {
      _project_id: this.project_uid,
      settingPanel: { // no kiosk setting, just app setting
        previewHeight: 650,
        templateIndex: undefined,
        templateWidthSize: 550,
        eventIndex: -1,
        eventPrizeIndex: -1,
        previewPrizeEffect: false,
        canvasWidth: 800,
        onMosaicControl: false,
      },
      printerSetting: defaultSetting,
      initPrinterSetting: JSON.stringify(defaultSetting),
      undoPrinterSetting: '',
      currentTab: 'intro'
    }
  },
  methods: {
    moveTab(tabName) {
      this.currentTab = tabName;
    },
    saveData() {
      console.log('this.undoPrinterSetting', this.undoPrinterSetting)
      const sameMosaicOptions = this.$u.equal(JSON.parse(this.undoPrinterSetting).mosaic, this.printerSetting.mosaic, { size: false, except: ['__ob__', 'use'] });
      console.log('test', sameMosaicOptions);
      if (!sameMosaicOptions && !confirm("The mosaic setting is reset. Do you want to continue?")) return;
      
      const filesSetter = function (key, file, target) {      
        if (file instanceof File) target[key] = file;
      };
      const setting = this.printerSetting;
      const files = {};

      filesSetter('introBg', setting.intro.backgroundImage, files);
      filesSetter('postViewBg', setting.postView.backgroundImage, files);
      filesSetter('templateBtn', setting.template.printButton.url, files);
      filesSetter('templateBg', setting.template.backgroundImage, files);
      filesSetter('templates', setting.template.backgroundImage, files);
      setting.template.templates.single.map(item => item.url).forEach((item, index) => {
        filesSetter('templates-single/' + index, item, files);
      });
      setting.template.templates.double.map(item => item.url).forEach((item, index) => {
        filesSetter('templates-double/' + index, item, files);
      });
      setting.template.templates['double-photo_only'].map(item => item.url).forEach((item, index) => {
        filesSetter('templates-double-photo_only/' + index, item, files);
      });
      setting.template.templates['coupon'].map(item => item.url).forEach((item, index) => {
        filesSetter('templates-coupon/' + index, item, files);
      });
      setting.template.coupon.map(item => item.url).forEach((item, index) => {
        filesSetter('coupon/' + index, item, files);
      });
      setting.template.stickers.map(item => item.url).forEach((item, index) => {
        filesSetter('stickers/' + index, item, files);
      });
      filesSetter('printingBg', setting.printing.backgroundImage, files);
      filesSetter('printingAd', setting.printing.advertisement, files);
      filesSetter('printEndBg', setting.printEnd.backgroundImage, files);
      filesSetter('printEndPS', setting.printEnd.prizeSound, files);
      filesSetter('printEndPC', setting.printEnd.prizeContent, files);
      filesSetter('customBg', setting.custom.backgroundImage, files);
      // setting.event.events.forEach((event, eventIndex) => {
      //   event.prizes.forEach((prize, prizeIndex) => {
      //     filesSetter('prize/' + eventIndex + '/' + prizeIndex, prize.url, files);
      //   });
      // });
      
      setting.event.prizes.forEach((prize, prizeIndex) => {
        filesSetter('prize/' + prizeIndex, prize.url, files);
      });
      
      filesSetter('mosaicImage', setting.mosaic.mosaicImage, files);
        
      if (setting.printing.advertisement instanceof File) {
        console.log(setting.printing.advertisement.type);
      }


      setting.langPack.data.forEach(langObj => {
        if (langObj) {
          for (const key in langObj) {
            filesSetter('langPack/' + langObj.lang + '/' + key, langObj[key], files);            
          }
        }
      });

      // console.log('files', files);
      this.$blockOn({
        text:'Saving',
        delay: 3000,
        color: 'orange',
        sync: true,
      });

      const saveSetting = _=> {
        if (!sameMosaicOptions) this.saveMosaic();
        axios.post(this.API_URL, JSON.stringify(setting), {
          headers: {
            'Content-Type': 'application/json'
          },
          withCredentials: true,
        })
          .then(response => {
            console.log(response);
            this.$blockOff();
            this.$toastShow({
              type: 'default',
              title: 'Save Success',
              content: `All information has been saved.`
            });
            this.undoPrinterSetting = JSON.stringify(this.printerSetting);
          })
          .catch(e => {
            console.log(e);
            this.$toastShow({
              type: 'error',
              title: 'Setting save failed',
              content: `Setting save failed. please try again.`,
              infinity: true
            });
          });
      };
      
      if (!Object.keys(files).length) saveSetting();
      
      const checkUpload = _=> {
        this.$blockOn({ percentage: savedFileSize / totalFileSize * 100, sync: true });
        
        if (totalFileSize === savedFileSize) {
          console.log(setting);
          saveSetting();
          return;
        }
      };
      
      const settingSetter = (key, value) => {
        console.log(key, value);
        const singleKeys = ['introBg', 'postViewBg', 'templateBtn', 'templateBg', 'templates', 'printingBg', 'printingAd', 'printEndBg', 'printEndPS', 'printEndPC', 'customBg', 'eventStamp', 'mosaicImage'];
        if (singleKeys.indexOf(key) !== -1) {
          switch (key) {
            case "introBg":
              setting.intro.backgroundImage = value;
              break;
            case "postViewBg":
              setting.postView.backgroundImage = value;
              break;
            case "templateBtn":
              setting.template.printButton.url = value;
              break;
            case "templateBg":
              setting.template.backgroundImage = value;
              break;
            case "templates":
              setting.template.backgroundImage = value;
              break;
            case "printingBg":
              setting.printing.backgroundImage = value;
              break;
            case "printingAd":
              setting.printing.advertisement = value;
              break;
            case "printEndBg":
              setting.printEnd.backgroundImage = value;
              break;
            case "printEndPS":
              setting.printEnd.prizeSound = value;
              break;
            case "printEndPC":
              setting.printEnd.prizeContent = value;
              break;
            case "customBg":
              setting.custom.backgroundImage = value;
              break;
            case "eventStamp":
              setting.event.stampImage = value;
              break;
            case "mosaicImage":
              setting.mosaic.mosaicImage = value;
              break;
          }
        }
        else {
          if (key.search('templates-single') !== -1) {
            const index = key.split('/')[1];
            setting.template.templates.single[index].url = value;
          }
          if (key.search('templates-double-photo_only') !== -1) {
            const index = key.split('/')[1];
            setting.template.templates['double-photo_only'][index].url = value;
          } else if (key.search('templates-double') !== -1) {
            const index = key.split('/')[1];
            setting.template.templates.double[index].url = value;
          } else if (key.search('templates-coupon') !== -1) {
            const index = key.split('/')[1];
            setting.template.templates.coupon[index].url = value;
          }
          if (key === 'coupon/0') {
            const index = key.split('/')[1];
            setting.template.coupon[index].url = value;
          }
          if (key.search('stickers') !== -1) {
            const index = key.split('/')[1];
            setting.template.stickers[index].url = value;
          }
          if (key.search('langPack') !== -1) {
            const currentLang = key.split('/')[1];
            const rowName = key.split('/')[2];
            for(const langObj of setting.langPack.data) {
              if (langObj.lang === currentLang) {
                langObj[rowName] = value;
                break;
              }
            }
          }
          if (key.search('prize') !== -1) {
            const [ _, prizeIndex ] = key.split('/');
            setting.event.prizes[prizeIndex].url = value;
          }
        }
      };
      
      let totalFileSize = 0;
      let savedFileSize = 0;
      for (const key in files) {
        totalFileSize += files[key].size; 
        uploadFile(files[key])
          .then(url => {
            settingSetter(key, url);
            savedFileSize += files[key].size;
            checkUpload();
          })
          .catch(e => {
            savedFileSize += files[key].size;
            checkUpload();
            console.error(e);
            this.$toastShow({
              type: 'error',
              title: 'File upload failed',
              content: `File upload failed. please try again.`,
              infinity: true
            });
          });
      }
      
    },
    saveMosaic() {
      console.log('config', config)
      console.log('test', this.$u.deassign(this.printerSetting.mosaic, 'rows', 'width', 'height', 'top', 'left', { columns: 'cols', url: 'mosaicImage' }));
      axios.post(`${ config.MOSAIC_API_URL }/init`, 
        JSON.stringify({
          project_uid: this.project_uid,
          settings_uid: this.printer_settings_uid,
          ...this.$u.deassign(this.printerSetting.mosaic, 'rows', 'width', 'height', 'top', 'left', { columns: 'cols', url: 'mosaicImage' })
        }),
        {
            headers: {
                'Content-Type': 'application/json',
            }
        }
      )
        .then(d => {
          console.log('save success', d.data, d.data.result);
          if (d.data.result) {
            this.$toastShow({
              title: 'Success save mosaic data',
              content: `Success save mosaic data`
            });
          } else {
            this.$toastShow({
              type: 'error',
              title: 'Fail save mosaic data',
              content: `Fail save mosaic data : ${ d.data.context }`
            });
          }
        }, d => {
          console.log('save fail')
          this.$toastShow({
            type: 'error',
            title: 'Fail save mosaic data',
            content: `Fail save mosaic data`
          });
        });
    }
  },
  computed: {
    API_URL() {
      return `${config.API_URL}/views_printer/${ this.project_uid }/${ this.printer_settings_uid }`;
    }
  },
  created() {
    axios.get(this.API_URL)
      .then(res => {
        console.log(res);
        if (res.data.data._inited) {
          // check event
          if (res.data.data.event.events) {
            console.log('updated event');
            res.data.data.event = defaultSetting.event;
          }
          this.printerSetting = res.data.data;
          const { templates: templates_setting } = this.printerSetting.template;
          // console.log('test', templates_setting, defaultSetting.template.templates); 
          if (!templates_setting['double-photo_only']) templates_setting['double-photo_only'] = defaultSetting.template.templates['double-photo_only'].slice(0);
          if (!templates_setting.coupon) templates_setting.coupon = defaultSetting.template.templates.coupon.slice(0);
          if (!this.printerSetting.template.coupon) this.printerSetting.template.coupon = defaultSetting.template.coupon;
          // console.log(`template_setting['double-photo_only']`, templates_setting['double-photo_only'], `defaultSetting.template['double-photo_only'].slice(0)`, defaultSetting.template.templates['double-photo_only'].slice(0));
          this.undoPrinterSetting = JSON.stringify(this.printerSetting);
        }
        else {
          axios.post(this.API_URL, JSON.stringify(Object.assign(defaultSetting, res.data.data)), {
            headers: {
              'Content-Type': 'application/json',
            },
            withCredentials: true,
          })
            .then(new_res => {
              this.printerSetting = new_res.data.data;
              //-----guide

              this.undoPrinterSetting = JSON.stringify(this.printerSetting);
            });
        }
      })
      .catch(e => {
        this.$blockOn({
          text: 'Wrong Approach',
          delay: 3000,
          color: 'orange',
          sync: true,
        });
      });
    this.$eventBus.$on('save-apply', _=> this.saveData());

    this.$eventBus.$on('init-tab', _=> this.printerSetting[this.currentTab] = JSON.parse(this.initPrinterSetting)[this.currentTab]);
    this.$eventBus.$on('undo-tab', _=> this.printerSetting[this.currentTab] = JSON.parse(this.undoPrinterSetting)[this.currentTab]);
  },
  mounted() {
    if (this.$u.isIE()) {
      this.$toastShow({
        type: 'warn',
        title: 'Using Internet Explorer',
        content: `This site is not compatible with Explorer, so please use Chrome.`,
        infinity: true
      });
    }
  },
  components: {
    tabNav,
    tabContents
  }
}

</script>

<style lang='scss'>
  * {
    margin: 0;
    padding: 0;
  }
  input, button {
    outline: none;
  }
  .printer-setting {
    min-width: 800px;
    max-width: 1080px;
  }
</style>