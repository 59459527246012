<template>
  <div class='printer-setting-nav'>
    <div class='brief-infomation'>
      <dt>{{ '' }}</dt><dd>{{ '' }}</dd>
    </div>
    <nav>
      <transition-group name="flip-list" tag="ul">
        <tab-nav-item
          v-for="tabName of tabFlowWarp"
          @link="link(tabName)"
          :tabName="tabName"
          :currentTab="currentTab"
          :key="tabName"></tab-nav-item>
      </transition-group>
    </nav>
  </div>
</template>

<script>
  import tabNavItem from './tab-nav-item.vue';

  export default {
    props: ['currentTab', 'tabFlow'],
    methods: {
      link(tabName) {
        this.$emit('moveTab', tabName);
      },
      routeObj(tabName) {
        return routes[tabName];
      },
    },
    computed: {
      tabFlowWarp() {
        return this.tabFlow.concat('event', 'langPack', 'mosaic');
      }
    },
    components: {
      tabNavItem
    }
  };
</script>

<style lang='scss'>
  nav {
    margin-top: 10px;
  }
  .flip-list-move {
    transition: transform 0.1s;
  }
  .printer-setting-nav {
    .brief-infomation {
      background: white;
      padding: 20px;
      box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.5);
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
    }
    dt {
      font-size: 20px;
      padding: 0 20px;
      border-right: 1px solid black;
    }
    dd {
      margin-left: 20px;
    }
    dt, dd {
      display: inline-block;
    }
    ul {
      margin: 0;
      padding: 0;
      box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.5);
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
    }
    li {
      &:nth-of-type(1) {
        border-bottom-left-radius: 5px;
      }
      &:nth-last-of-type(1) {
        border-bottom-right-radius: 5px;
      }
      position: relative;
      width: percentage(1 / 9);
      min-width: 65px;
      height: 80px;
      cursor: pointer;
      display: inline-block;
      list-style: none;
      text-align: center;
      box-sizing: border-box;
      border-left: 1px solid #ccc;
      line-height: 120px;
      background: no-repeat center 20px / 28px;

      &.current-tab {
        color: white;
        background-color: #ff8400;
        &:after {
          border: 10px solid transparent;
          border-top-color: #ff8400;
          border-bottom: 0;
          margin-left: -10px;
          margin-bottom: -10px;
          transition: border 0.2s, margin-left 0.2s, margin-bottom 0.2s;
        }
      }

      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 50%;
        width: 0;
        height: 0;
        border: 0px solid transparent;
        border-top-color: #ff8400;
        border-bottom: 0;
        margin-left: 0px;
        margin-bottom: 0px;
        
      }
      &:not(.current-tab):hover {
        background-color: #eee;
      }

    }
  }
</style>