<template>
  <div>
    <label
      :for="id"
      >
      <input
        type='file'
        :accept="accept"
        :id="id"
        @change="syncValue"
        />
      <input
        type="text"
        @focus="blur"
        @click="selectFile" 
        :value="fileName"
        v-if="!preview"
        />
      <button
        style="background-image: url(/../../assets/icon/baseline-add-white-24px.svg);"
        @click="selectFile"
        v-if="!preview"></button>
      <img 
        :src="fileURL"
        v-if="preview" />
    </label>
  </div>
</template>

<script>
export default {
  model: {
    event: 'change'
  },
  props: {
    id: {
      type: String,
      default: function () {
        return `y-file-${ this._uid }`;
      }
    },
    value: {},
    accept: {
      type: String,
      default: 'image/*'
    },
    preview: {
      type: Boolean,
      default: false
    },
    guide: Object
  },
  data() {
    return {
      imageData: ''
    }
  },
  computed: {
    fileName: {
      get() {
        if (this.value instanceof File) {
          return this.value.name;
        } else {
          return this.value;
        }
      },
      set() {}
    },
    fileURL() {
      if (this.value instanceof File) {
        return URL.createObjectURL(this.value);
      } else {
        return this.value;
      }
    }
  },
  methods: {
    blur(e) {
      e.target.blur();
    },
    selectFile(e) {
      this.blur(e);
      this.$el.firstChild.firstChild.click();
    },
    contentValidate(type, files) {
      const content = document.createElement(type);
        content.src = URL.createObjectURL(files);
        content
          .play()
          .then(_=> {
            content.pause();
            this.fileName = files.name;
            this.$emit('change', files);
          })
          .catch(e => {
            this.$toastShow({
              type: 'error',
              title: 'Not Available File',
              content: `Can not play because the encoding is incorrect. (allowed encoding: H.264)`
            });
            console.error('Not available file');
          });
    },
    syncValue(e) {
      if (!e.target.files.length) return;
      const self = this;
      const fileType = e.target.files[0].type.split('/')[0];
      
      if (this.accept.search(fileType) === -1) return this.errorAlarm(this.accept);

      switch (fileType) {
        case 'video':
          this.contentValidate('video', e.target.files[0]);
          break;
        case 'audio':
          this.contentValidate('audio', e.target.files[0]);
          break;
        case 'image':
          const img = new Image();
          img.onload = _=> {
            if (!this.guideCheck(img)) {
              this.$toastShow({
                type: 'warn',
                title: 'Size does not fit the guide',
                content: `If the size does not match the guide, the image may be distorted.
(Recommended size: ${ JSON.stringify(this.guide).slice(1, -1).replace(/"/g, '') })`,
                // infinity: true
              });
            }
            self.fileName = e.target.files[0].name;
            self.$emit('change', e.target.files[0]);
            self.$emit('changed');
          };
          img.onerror = e => this.errorAlarm(this.accept);
          img.src = URL.createObjectURL(e.target.files[0]);
          break;
      }
    },
    errorAlarm(type) {
      type = type.split(',').map(item => item.trim().split('/')[0]).join(' or ');
      this.$toastShow({
        type: 'error',
        title: 'Not Available File',
        content: `Only ${ type } files can be uploaded.`,
        // infinity: true
      });
      console.error('Not available file');
    },
    guideCheck(obj) {
      for (const key in this.guide) {
        if (obj[key] !== this.guide[key]) {
          return false;
        }
      }
      return true;
    }
  },
}
</script>


<style lang="scss" scoped>
  div {
    display: inline-block;
  }
  input[type="file"] {
    display: none;
  }
  label {
    cursor: pointer;
    display: inline-block;
    position: relative;
    box-shadow: 0 0 0 1px #FF8400;
    border-radius: 5px;
    background: white;
    vertical-align: middle;
    width: 100%;
    overflow: hidden;
  }
  input[type="text"] {
    cursor: pointer;
    width: 100%;
    padding: 5px;
    box-sizing: border-box;
    border: 0;
  }
  button {
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    width: 27px;
    height: 27px;
    border: 0;
    border-radius: 5px;
    background: #FF8400;
    vertical-align: middle;
  }
  img {
    width: 75px;
  }
</style>

