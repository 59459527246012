export default {
  intro: {
    name: '인트로',
    icon: './assets/icon/pointerhand.svg'
  },
  postView: {
    name: '메인',
    icon: './assets/icon/main.svg'
  },
  template: {
    name: '템플릿',
    icon: './assets/icon/template.svg'
  },
  printing: {
    name: '인화중',
    icon: './assets/icon/project_request.svg'
  },
  printEnd: {
    name: '인화완료',
    icon: './assets/icon/project_request.svg'
  },
  custom: {
    name: '커스텀',
    icon: './assets/icon/custom.svg'
  },
  event: {
    name: '이벤트',
    icon: './assets/icon/event.svg'
  },
  langPack: {
    name: '언어팩',
    icon: './assets/icon/language.svg'
  }, 
  mosaic: { 
    name: '모자이크',
    icon: './assets/icon/mosaic.svg'
  }
};

// export default [{
//   tab: 'intro',
//   name: '인트로',
//   icon: './assets/icon/pointerhand.svg'
// }, {
//   tab: 'postView',
//   name: '메인',
//   icon: './assets/icon/main.svg'
// }, {
//   tab: 'template',
//   name: '템플릿',
//   icon: './assets/icon/template.svg'
// }, {
//   tab: 'printing',
//   name: '인화중',
//   icon: './assets/icon/project_request.svg'
// }, { 
//   tab: 'printEnd',
//   name: '인화완료',
//   icon: './assets/icon/project_request.svg'
// }, { 
//   tab: 'custom',
//   name: '커스텀',
//   icon: './assets/icon/custom.svg'
// }, { 
//   tab: 'event',
//   name: '이벤트',
//   icon: './assets/icon/event.svg'
// }, { 
//   tab: 'langPack',
//   name: '언어팩',
//   icon: './assets/icon/language.svg'
// }, { 
//   tab: 'mosaic',
//   name: '모자이크',
//   icon: './assets/icon/mosaic.svg'
// }];