<template>
  <div class='setting-panel' v-bar>
    <div class='setting-contents'>
      <div class='setting-body'>
        <p>Template is the page for selecting photo template and check the preview photo before printing.</p>
        <div class='hr'></div>
        <p>Duplicate settings.</p>
        <hs-select :options="['None', 'Kiosk 1']" v-model="setting.copy"></hs-select>  
      </div>
      <div class='setting-title'>
        Layout
      </div>
      <div class='setting-body'>
        <!-- <p><b>Upload Image</b></p> -->
        <div>
          <dt>Mode</dt>
          <dd>
            <hs-select
              :options="['single', 'double', 'double-photo_only', 'coupon']"
              v-model="setting.type"
              @changed="() => {}"></hs-select>
          </dd>
        </div>
        <div>
          <dt>Background</dt>
          <dd>
            <hs-file
              style='width: 100%;'
              v-model="setting.backgroundImage"
              :guide="{ width: 1080, height: 1920 }"></hs-file>
          </dd>
        </div>
        <div v-if="setting.type === 'coupon'">
          <dt>Coupon</dt>
          <dd>
            <hs-file
              style='width: 100%;'
              v-model="setting.coupon[0].url"
              :guide="{ width: 900, height: 1200 }"></hs-file>
          </dd>
        </div>
        <div>
          <hs-checkbox
            v-model="useMainBackground"
            @change="checkMainBackground(useMainBackground)">Use background image like main</hs-checkbox>
        </div>
        <div>
          <dt>Top Margin</dt>
          <dd>
            <hs-number v-model="setting.paddingTop" :maxlength="3" :min="0" :max="1920">px</hs-number>
          </dd>
        </div>
      </div>
      <div class='setting-title'>
        Photo Template
      </div>
      <div class='setting-body'>

        <p><b>Upload Template Image</b></p>
        <transition-group name="fade-panel-item">
          <div
            class="template-item"
            v-for="(template, index) of setting.templates[templateType]"
            :key="'template' + index">
            <hs-list-file
              v-model="setting.templates[templateType][index]"
              @changed="settingPanel.templateIndex = index"
              :change-button="true"
              :guide="{ width: 1200, height: 1800 }"></hs-list-file>
            <button class='detail-button' @click="togglePanel(index)"></button>
            <button class='delete-button' @click="removeImageFile(setting.templates[templateType], index)"></button>
            <transition name="fade-panel-detail">
            <div class='detail-panel' v-show="index === settingPanel.templateIndex">
              <p><b>Photo Size</b></p>
              <div>
                <dt>Width</dt>
                <dd><hs-number v-model="setting.templates[templateType][index].w" :maxlength="4" :min="500" :max="1200">px</hs-number></dd>
              </div>
              <div>
                <dt>Height</dt>
                <dd><hs-number v-model="setting.templates[templateType][index].h" :maxlength="4" :min="500" :max="1800">px</hs-number></dd>
              </div>
              <div>
                <dt><b>Photo Rotation</b></dt>
                <dd><hs-number v-model="setting.templates[templateType][index].r" :maxlength="3" :min="0" :max="360">deg</hs-number></dd>
              </div>
              <div>
                <p><b>Photo Position</b></p>
                <dt>Top</dt>
                <dd><hs-number v-model="setting.templates[templateType][index].y" :maxlength="4" :min="0" :max="1800">px</hs-number></dd>
                <dt>Left</dt>
                <dd><hs-number v-model="setting.templates[templateType][index].x" :maxlength="4" :min="0" :max="1200">px</hs-number></dd>
              </div>
              <div>
                <button class='text-button' @click="setHorizenCenter(setting.templates[templateType][index])">Horizen-center</button>
                <button class='text-button' @click="setVerticalCenter(setting.templates[templateType][index])">Vertical-center</button>
              </div>
            </div>
            </transition>
          </div>
        </transition-group>
        <p><button class='text-button' @click="addImageFile(setting.templates[templateType], 'image')">Add Template</button></p>     
      </div>   
      <div class='setting-title'>
        Add Printing Date
      </div>
      <div class='setting-body'>
        <p><hs-checkbox v-model="setting.useDate">Display Printing Date</hs-checkbox></p>
        <div class='detail-panel' v-show="setting.useDate">
          <div>
            <dt>
              Date Form
            </dt>
            <dd>
              <hs-input v-model="setting.date.text"></hs-input>
            </dd>
          </div>
          <div>
            <dt>
              Date Color
            </dt>
            <dd>
              <hs-color-simple v-model="setting.date.color"></hs-color-simple>
            </dd>
          </div>
          <div>
            <dt>
              Font Size
            </dt>
            <dd>
              <hs-number v-model="setting.date.fontSize" :maxlength="3" :min="5" :max="2000">px</hs-number>
            </dd>
          </div>
          <div>
            <dt>
              Top
            </dt>
            <dd>
              <hs-number v-model="setting.date.top" :maxlength="4" :min="0" :max="1800">px</hs-number>
            </dd>
          </div>
          <div>
            <dt>
              Left
            </dt>
            <dd>
              <hs-number v-model="setting.date.left" :maxlength="4" :min="0" :max="1200">px</hs-number>
            </dd>
          </div>
          <div>
            <dt>
              Rotate
            </dt>
            <dd>
              <hs-number v-model="setting.date.r" :maxlength="3" :min="0" :max="360">px</hs-number>
            </dd>
          </div>
        </div>
      </div>
      <div class='setting-title'>
        Add Stickers
      </div>
      <div class='setting-body'>
        <p><hs-checkbox v-model="setting.useSticker">Use Stickers</hs-checkbox></p>
        <div v-show="setting.useSticker">
          <transition-group name="fade-panel-item">
          <p
            v-for="(sticker, index) of setting.stickers"
            :key="'sticker' + index">
            <!-- <hs-file v-model="setting.stickers[index].url"></hs-file> -->
            <hs-list-file v-model="setting.stickers[index]" :change-button="false"></hs-list-file>
            <button class='delete-button' @click="removeImageFile(setting.stickers, index)"></button>
          </p>
          </transition-group>
          <p><button class='text-button' @click="addImageFile(setting.stickers, 'image')">Add Sticker</button></p>
          <div>
            <dt>
              Sticker Panel Width
            </dt>
            <dd>
              <hs-number v-model="setting.stickerPanel.width" :maxlength="3" :min="300" :max="600">px</hs-number>
            </dd>
          </div>
          <div>
            <dt>
              Sticker Panel Height
            </dt>
            <dd>
              <hs-number v-model="setting.stickerPanel.height" :maxlength="3" :min="50" :max="200">px</hs-number>
            </dd>
          </div>
          <div>
            <dt>
              Sticker Panel Margin Top
            </dt>
            <dd>
              <hs-number v-model="setting.stickerPanel.marginTop" :maxlength="4" :min="0" :max="1800">px</hs-number>
            </dd>
          </div>
        </div>
      </div>
      
      <div class='setting-title'>
        Set Print Button
      </div>
      <div class='setting-body'>
        <div>
          <dt>
            <b>Print Button Image</b>
          </dt>
          <dd>
            <hs-file
              v-model="setting.printButton.url"
              :accept="'image/*'"
              :guide="{ width: setting.printButton.w, height: setting.printButton.h }"></hs-file>
          </dd>
        </div>
        <p><b>Print Button Size</b></p>
        <div>
          <dt>Width</dt>
          <dd><hs-number v-model="setting.printButton.w" :maxlength="4" :min="50" :max="1200">px</hs-number></dd>
        </div>
        <div>
          <dt>Height</dt>
          <dd><hs-number v-model="setting.printButton.h" :maxlength="4" :min="50" :max="1800">px</hs-number></dd>
        </div>
        <div>
          <p><b>Print Button Position</b></p>
          <dt>Top</dt>
          <dd><hs-number v-model="setting.printButton.y" :maxlength="4" :min="0" :max="1920">px</hs-number></dd>
          <dt>Left</dt>
          <dd><hs-number v-model="setting.printButton.x" :maxlength="4" :min="0" :max="1080">px</hs-number></dd>
        </div>
        <div>
          <button class='text-button' @click="setHorizenCenter(setting.printButton, 1080)">Horizen-center</button>
          <button class='text-button' @click="setVerticalCenter(setting.printButton, 1920)">Vertical-center</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        openTemplatePanelIndex: undefined,
        useMainBackground: false
      }
    },
    methods: {
      togglePanel(index) {
        this.settingPanel.templateIndex === index 
          ? this.settingPanel.templateIndex = undefined
          : this.settingPanel.templateIndex = index;
      },
      guideCheck(obj, guide) {
        for (const key in guide) {
          if (obj[key] !== guide[key]) {
            return false;
          }
        }
        return true;
      },
      addImageFile(target, accetType) {
        const input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.onchange = e => {
          if (input.files.length) {
            const type = input.files[0].type.split('/')[0];
            if (accetType.search(type) === -1) {
              this.$toastShow({
                type: 'error',
                title: 'Not Available File',
                content: `Only ${ type } files can be uploaded.`,
                // infinity: true
              });
              return console.error('Not available file.');
            }
            
            const img = new Image();
            img.onload = _=> {
              if (target === this.setting.templates && !this.guideCheck(img, { width: 1200, height: 1800 })) {
                this.$toastShow({
                  type: 'warn',
                  title: 'Size does not fit the guide',
                  content: `If the size does not match the guide, the image may be distorted.
                            (Recommended size: ${ JSON.stringify({ width: 1200, height: 1800 }).slice(1, -1).replace(/"/g, '') })`,
                });
              }
            };
            img.src = URL.createObjectURL(input.files[0]);


            if (target === this.setting.templates[this.templateType]) {
              target.push({
                name: input.files[0].name,
                r: 0,
                url: input.files[0],
                h: 1040,
                w: 1040,
                x: 80,
                y: 200
              });
              this.settingPanel.templateIndex = target.length - 1;
            }
            if (target === this.setting.stickers) {
              target.push({
                name: input.files[0].name,
                url: input.files[0],
              });
            }
            
          }
        }
        input.click();
      },
      removeImageFile(target, index) {
        if (target.length > 1) {
          target.splice(index, 1);
          this.settingPanel.templateIndex = undefined;
        } else {
          this.$toastShow({
            type: 'error',
            title: 'Failed to delete',
            content: `There must be at least one image.`
          });
        }
      },
      setHorizenCenter(target, width = 1200) {
        target.x = Math.floor((width - target.w) / 2);
      },
      setVerticalCenter(target, height = 1800) {
        target.y = Math.floor((height - target.h) / 2);
      },
      checkMainBackground(bool) {
        if (bool) {
          this.$background = this.setting.backgroundImage;
          this.setting.backgroundImage = this.$parent.printerSetting['postView'].backgroundImage;
        }
        else {
          this.setting.backgroundImage = this.$background;
        }
      }
    },
    computed: {
      setting() {
        return this.$parent.printerSetting[this.$parent.currentTab];
      },
      settingPanel() {
        return this.$parent.settingPanel;
      },
      templateType() {
        return this.setting.type;
      }
    },
  };
</script>

<style lang='scss' scoped>
  .fade-panel-item-enter-active,
  .fade-panel-item-leave-active {
    transition: opacity .2s, transform .2s;
    transform-origin: top;
  }
  .fade-panel-item-enter,
  .fade-panel-item-leave-to
    {
    transform: scaleY(0);
    opacity: 0;
  }
  .template-item {
    margin: 20px 0;
  }
  .detail-button, .delete-button {
    cursor: pointer;
    width: 27px;
    height: 27px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background: white;
    vertical-align: middle;
  }
  .detail-button {
    background: url(/../../assets/icon/baseline-edit-24px.svg);
  }
  .delete-button {
    background: url(/../../assets/icon/baseline-delete-24px.svg);
  }
  .detail-panel {
    // display: none;
    
    background: #f1f1f1;
    padding: 1px 20px;
    border-radius: 5px;
    box-sizing: border-box;
    p {
      margin: 10px 0;
      vertical-align: middle;
      > * {
        vertical-align: middle;
      }
    }
    dt, dd {
      margin-bottom: 10px;
      display: inline-block;
      vertical-align: middle;
    }
    dt {
      min-width: 70px;
      width: 40%;
    }
    dd {
      width: 55%;
    }
  }
  .text-button {
    cursor: pointer;
    height: 27px;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 5px;
    box-sizing: border-box;
    background: white;
    vertical-align: middle;
  }
  dt, dd {
    margin-bottom: 20px;
    display: inline-block;
    vertical-align: middle;
  }
  dt {
    min-width: 100px;
    width: 30%;
  }
  dd {
    width: 60%;
  }
</style>
