<template>
  <div class='setting-panel' v-bar>
    <div class='setting-contents'>
      <div class='setting-body'>
        <p>Main is a post-displayed page, people can select their post to print it out.</p>
        <div class='hr'></div>
        <p>Duplicate settings.</p>
        <hs-select :options="['None', 'Kiosk 1']" v-model="setting.copy"></hs-select>  
        <p>
          <dt>
            Admin Password
          </dt>
          <dd>
            <hs-input v-model="setting.adminPassword" type="password"></hs-input>
          </dd>
        </p>
      </div>
      <div class='setting-title'>
        Layout
      </div>
      <div class='setting-body'>
        <!-- <p><b>Upload Image</b></p> -->
        <div>
          <dt>Background</dt>
          <dd>
            <hs-file
              style='width: 100%;'
              v-model="setting.backgroundImage"
              :guide="{ width: 1080, height: 1920 }"></hs-file>
          </dd>
        </div>
        
        <div>
          <dt>
            Main Color
          </dt>
          <dd>
            <hs-color-simple v-model="setting.mainColor"></hs-color-simple>
          </dd>
        </div>
        <div>
          <dt>Columns</dt>
          <dd>
            <hs-number v-model="setting.cols" :maxlength="3" :min="3" :max="6">cols</hs-number>
          </dd>
        </div>
        <div>
          <dt>Top Margin</dt>
          <dd>
            <hs-number v-model="setting.paddingTop" :maxlength="3" :min="setting.headerHeight" :max="1920">px</hs-number>
          </dd>
        </div>
        <!-- <p><b>Header/Footer Height Size</b></p> -->
        <div>
          <dt>Header</dt>
          <dd>
            <hs-number v-model="setting.headerHeight" @changed="minPaddingTop" :maxlength="3" :min="0" :max="600">px</hs-number>
          </dd>
        </div>
        <div>
          <dt>Footer</dt>
          <dd>
            <hs-number v-model="setting.footerHeight" :maxlength="3" :min="0" :max="600">px</hs-number>
          </dd>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  let isPush = false;

  export default {
    data() {
      return {
        postData: new Array(50),

      }
    },
    methods: {
      getRandomColor(index) {
        return '#' + tools.getHsvColorHex(index / 100,);
      },
      panelDown(e) {
        console.log(e);
        isPush = true;
      },
      panelMove(e) {
        if (isPush) console.log(e);
      },
      panelUp(e) {
        console.log(e);
        isPush = false;
      },
      minPaddingTop() {
        if (this.setting.paddingTop < this.setting.headerHeight) {
          return this.setting.paddingTop = this.setting.headerHeight;
        }
      }
    },
    computed: {
      setting() {
        return this.$parent.printerSetting[this.$parent.currentTab];
      },
    },
  };
</script>

<style lang='scss' scoped>
  dt, dd {
    margin-bottom: 20px;
    display: inline-block;
    vertical-align: middle;
  }
  dt {
    min-width: 100px;
    width: 30%;
  }
  dd {
    width: 60%;
  }
</style>