<template>
  <div
    class="hs-block"
    @mousedown.prevent="_=>{}">
    <transition name="fade">
      <div
        class="text"
        :style="{ color,
                  backgroundColor }"
        v-show="onBlock">
        <div
          class='loading-bar'
          :style="{ width: percentage + '%' }"></div>
        {{ text }}
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  data() {
    return {
      backgroundColor: 'rgba(0,0,0,0.5)',
      color: 'white',
      onBlock: false,
      text: '',
      percentage: 0
    }
  },
  mounted() {
    this.$eventBus.$on('hsBlockOn', data => {
      data.backgroundColor ? this.backgroundColor = data.backgroundColor : 0;
      data.color ? this.color = data.color : 0;
      data.text ? this.text = data.text : 0;
      data.percentage ? this.percentage = data.percentage : 0;
      let delay = data.delay || 500;
      this.onBlock = true;
      if (!data.sync) {
        setTimeout(_=> {
          this.onBlock = false;
        }, delay);
      }
    });
    this.$eventBus.$on('hsBlockOff', _=> {
      this.blockgroundColor = 'rgba(0,0,0,0.5)';
      this.percentage = 0;
      this.onBlock = false;
      // console.log('hsBlockOff', this, this.onBlock);
    });
  }
}
</script>

<style lang="scss" scoped>
  .fade-leave-active {
    transition: opacity 1s;
  }
  .fade-leave-to {
    opacity: 0;
  }
  .hs-block {
    cursor: default;
    position: fixed;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    z-index: 100;
    > div {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 150px;
      width: 100vw;
      height: 100vh;
      .loading-bar {
        content: '';
        display: block;
        position: absolute;
        transition: width 0.2s;
        top: 0;
        left: 0;
        height: 5px;
        background: #43f192;
      }
    }
  }
</style>
