<template>
  <div>
    <label 
      class='hs-dropdown'
      :for="id">
      <input
        type='text'
        maxlength='5'
        :id="id"
        />
      <span>▼</span>
      <div class='hs-hidden-menu'>
        TEST
      </div>
    </label>
  </div>
</template>

<script>
export default {
  model: {
    event: 'change'
  },
  props: {
    id: {
      type: String,
      default: function () {
        return `y-dropdown-${ this._uid }`;
      }
    },
    value: String
  },
  data() {
    return {

    }
  },
  methods: {

  },
}
</script>


<style lang="scss" scoped>
  .hs-dropdown {
    position: relative;
    display: inline-block;
    border: 1px solid #ccc;
    padding: 5px;
    border-radius: 5px;
    box-sizing: border-box;
    &:hover .hs-hidden-menu {
      display: block;
    }
  }
  .hs-hidden-menu {
    display: none;
    position: absolute;
    left: 0;
    width: 100%;
    background: white;
    border: 1px solid #ccc;
    padding: 5px;
    box-sizing: border-box;
    border-radius: 5px;
  }
  input {
    border: 0;
    vertical-align: middle;
  }
  span {
    vertical-align: middle;
  }
</style>

