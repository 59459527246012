// const type = 'async';
// const images = [
//   'http://192.168.0.26:8080/assets/printend_default.png',
//   'https://cdn.sstatic.net/Sites/stackoverflow/img/sprites.svg',
//   'https://tistory4.daumcdn.net/tistory/91808/skin/images/header_default.jpgg',
//   'https://www.google.co.kr/images/branding/googlelogo/1x/googlelogo_color_272x92dp.pngg',
//   'https://s.pstatic.net/static/www/img/2018/sp_main_v180508.png',
//   'https://s0.2mdn.net/5585042/rider_banner_02_728x90.png',
// ];
const imgPreloader = (images, type = 'sync', callback) => {
  const el = document.createElement('img');
  el.setAttribute('style', `
    position: absolute;
    width: 1px;
    height: 1px;
  `);
  document.body.insertBefore(el, document.body.firstChild);

  const imgLoader = (url, callback) => {
    const img = new Image();
    img.onload = e => {
      if (typeof callback === 'function') callback(true, url, e);
    };
    img.onerror = e => {
      if (typeof callback === 'function') callback(false, url, e);
    };
    img.src = url;
  };

  const syncImgLoader = (images, callback) => {
    if (images.length) {
      const url = images.pop();
      if (typeof callback === 'function') callback(false, url);
      imgLoader(url, (r, url) => {
        // if (!r) console.warn('Fail Load', url);
        syncImgLoader(images, callback);
      });
    } else {
      if (typeof callback === 'function') callback(true);
    }
  };

  const asyncImgLoader = (images, callback) => {
    const counter = _=> {
      count++;
      if (count >= images.length) {
        if (typeof callback === 'function') callback();
      }
    };
    let count = 0;
    for (const image of images) {
      imgLoader(image, _=> counter());
    }
  };

  // console.time('Sync Image Load');

  if (type === 'sync') {
    syncImgLoader(images, (ended, url) => {
      // console.timeEnd('Sync Image Load');
      if (ended) {
        document.body.removeChild(el);
        if (typeof callback === 'function') callback();
      } else {
        el.src = url;
      }
    });
  } else if (type === 'async') {
    asyncImgLoader(images, _=> {
      // ** BETA
      // console.timeEnd('Sync Image Load');
      if (typeof callback === 'function') callback();
    })
  }
};

// imgPreloader(images);

export default imgPreloader;