<template>
  <div
    class='preview-panel'>
    <div
      class='virtual-kiosk'
      :style="{ width: 1080 * previewRatio + 'px' }">
      <preview-intro
        :current-tab="currentTab"
        :setting="printerSetting.intro"
        :setting-panel="settingPanel"></preview-intro>
      <preview-postView
        :current-tab="currentTab"
        :setting="printerSetting.postView"
        :setting-panel="settingPanel"></preview-postView>
      <preview-template
        :current-tab="currentTab"
        :setting="printerSetting.template"
        :mosaic="printerSetting.mosaic"
        :setting-panel="settingPanel"></preview-template>
      <preview-printing
        :current-tab="currentTab"
        :setting="printerSetting.printing"
        :mainSetting="printerSetting.postView"
        :setting-panel="settingPanel"></preview-printing>
      <preview-printEnd
        :current-tab="currentTab"
        :setting="printerSetting.printEnd"
        :setting-panel="settingPanel"></preview-printEnd>
      <preview-custom
        :current-tab="currentTab"
        :setting="printerSetting.custom"
        :setting-panel="settingPanel"></preview-custom>
      <preview-event
        :current-tab="currentTab"
        :setting="printerSetting.event"
        :template-setting="printerSetting.template"
        :setting-panel="settingPanel"></preview-event>
    </div>
  </div>
</template>

<script>
import previewIntro from './preview-intro.vue';
import previewPostView from './preview-postView.vue';
import previewTemplate from './preview-template.vue';
import previewPrinting from './preview-printing.vue';
import previewPrintEnd from './preview-printEnd.vue';
import previewCustom from './preview-custom.vue';
import previewEvent from './preview-event.vue';

export default {
  props: ['current-tab', 'printer-setting', 'setting-panel'],
  computed: {
    setting() {
      return this.printerSetting[this.currentTab];
    },
    introSetting() {
      return this.printerSetting['intro'];
    },
    postViewSetting() {
      return this.printerSetting['postView'];
    },
    previewRatio() {
      return this.settingPanel.previewHeight / 1920;
    },
  },
  components: {
    previewIntro,
    previewPostView,
    previewTemplate,
    previewPrinting,
    previewPrintEnd,
    previewCustom,
    previewEvent
  }
}
</script>

<style lang="scss" scoped>
  .preview-panel {
    display: flex;
    align-items: center;
    justify-content: center;
  }
</style>
