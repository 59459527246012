<template>
  <div
    v-show="currentTab === 'postView'"
    :style="{ position: 'relative',
              height: settingPanel.previewHeight + 'px' }">
    <preview-piece :background="setting.backgroundImage" :width="1080" :height="setting.headerHeight" :top="0" :z-index="3" :setting-panel="settingPanel"></preview-piece>
    <preview-piece :background="setting.backgroundImage" :width="1080" :height="setting.footerHeight" :bottom="0" :z-index="3" :setting-panel="settingPanel"></preview-piece>
    <preview-piece :background="setting.backgroundImage" :setting-panel="settingPanel">
      <div class='post-panel' slot-scope='data'>
        <div
          class='post-panel-margin'
          :style="{ 'padding-top': setting.paddingTop * data.ratio + 'px',
                    transform: `translateY(${ postPanelTop })` }"
          v-drag="dragOption">
          <div 
            class='post'
            v-for="(_, index) of postData" :key="_"
            :style="{ width: 100 / setting.cols + '%',
                      'padding-bottom': 100 / setting.cols + '%' }">
            <div class='post-padding'>
              <div
                class='post-image'
                :style="{ background: getRandomColor(index) }"></div>
            </div>
          </div>
        </div>
      </div>
    </preview-piece>
  </div>
</template>

<script>
import tools from '../lib/color-tools';
import previewPiece from './preview-piece.vue';
export default {
  props: ['currentTab', 'setting', 'setting-panel'],
  data() {
    return {
      ratio: this.settingPanel.previewHeight / 1920,
      postData: new Array(100),
      postPanelTop: 0,
      dragOption: {
        draggable_move: (delta, _, el) => {
          var min = -(el.offsetHeight
                      - this.settingPanel.previewHeight
                      + this.setting.footerHeight * this.ratio
                      + 10
                      );
          this.postPanelTop += delta.y;
          if (this.postPanelTop > 0) this.postPanelTop = 0;
          if (this.postPanelTop < min) this.postPanelTop = min;
          el.style['transform'] = `translateY(${ this.postPanelTop }px)`
        }
      }
    }
  },
  methods: {
    getRandomColor(index) {
      return '#' + tools.getHsvColorHex(index / 100);
    },
  },
  components: {
    previewPiece
  }
}
</script>

<style lang="scss" scoped>
  .post-panel {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    overflow: hidden;
    &-margin {
      position: relative;
      padding: 0 30px;
      box-sizing: border-box;
      width: 100%;
      line-height: 0;
    }
  }
  .post {
    position: relative;
    display: inline-block;
    overflow: hidden;
    &-padding {
      position: absolute;
      width: 100%;
      height: 100%;
      padding: 6px;
      box-sizing: border-box;
    }
    &-image {
      width: 100%;
      height: 100%;
      border-radius: 5px;
    }
  }
</style>

