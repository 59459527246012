<template>
  <div class='toast-panel' @mousedown.prevent="_=>{}">
    <transition-group name="toasts">
      <div
        v-for="(toast, index) of toastList"
        :class="{ [toast.data.type]:true }"
        :key="'toast' + index">
        <div
          class='toast-close'
          @click="remove(index)"></div>
        <h4 v-if="toast.data.title">{{ toast.data.title }}</h4>
        {{ toast.data.content }}
      </div>
    </transition-group>
  </div>
</template>

<script>
import Vue from 'vue';

const Toast = function (data, target, killTime = 5000) {
  this.data = data;
  target.push(this);
  if (!data.infinity) {
    setTimeout(_=> {
      if (target.indexOf(this) !== -1) target.splice(target.indexOf(this), 1);
    }, data.killTime);
  }
};

export default {
  created() {
    // console.log(this);
    Vue.prototype.$toastShow = this.show;
    Vue.prototype.$toastClear = this.clear;
    //   this.$toastShow({
    //     title: 'Toasted Plugins Test',
    //     content: 'Toasted'
    //   });
  },
  data() {
    return {
      toastList: []
    }
  },
  methods: {
    show(data) {
      new Toast({
        type: data.type ? data.type : 'default',
        title: data.title,
        content: data.content,
        infinity: data.infinity,
        killTime: data.killTime ? data.killTime : 5000
      }, this.toastList);
    },
    remove(index) {
      this.toastList.splice(index, 1);
    },
    clear() {
      while (this.toastList.pop()) {}
    }
  }
}
</script>


<style lang="scss" scoped>
  .toasts-enter-active,
  .toasts-leave-active {
    transition: opacity .2s, transform .2s;
    transform-origin: top;
  }
  .toasts-enter,
  .toasts-leave-to
    {
    transform: translateX(400px);
    opacity: 0;
  }
  .toast-panel {
    position: fixed;
    display: flex;
    z-index: 101;
    right: 3%;
    bottom: 5%;

    span > div {
      cursor: pointer;
      display: block;
      position: relative;
      width: 400px;
      // height: 100px;
      padding: 10px;
      box-sizing: border-box;
      border-radius: 5px;
      color: white;
      margin: 10px;
    }
  }
  .toast-close {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 10px;
    background: rgba(0,0,0,0.3);
    color: white;
    border-radius: 5px;
  }
  .default {
    background: #00d66a;
  }
  .warn {
    background: #e9aa00;
  }
  .error {
    background: #e02828;
  }
  .test {
    background: wheat;
  }
</style>
