<template>
  <div
    v-show="currentTab === 'template'">
    <preview-piece :background="setting.backgroundImage" :setting-panel="settingPanel">
      <div slot-scope="data">
        <div class='print-button-panel'>
          <div
            class='print-btn'
            :style="printButtonStyle">
            <div
              :style="setting.printButton.url | toBackgroundImage"></div>
          </div>
        </div>
        <div
          class='sticker-layer'
          v-show="setting.useSticker">
          <div
            class='sticker-panel'
            :style="stickerPanelStyle">
            <div
              class='sticker-image'
              v-for="(sticker, index) of setting.stickers"
              :style="sticker.url | toBackgroundImage"
              :key="'sticker' + index">
              <div
                :style="stickerImageStyle"></div>
              </div>
          </div>
        </div>
        <div 
          class='template-layer'
          :style="[ieStyle, { 'padding-top': setting.paddingTop * data.ratio + 'px' }]">
          <div
            class='template'
            v-for="(currentIndex, index) of currentTemplatesIndex"
            :style="{ width: settingPanel.templateWidthSize * data.ratio + 'px',
                      height: settingPanel.templateWidthSize * templateHeightRatio * data.ratio + 'px' }"
            :key="'ctems' + index">
            <div
              class='template-image'
              :style="templatesImageStyle[index]"></div>
            <div
              class='template-frame'
              :style="templatesFrames[index]"></div>
            <div
              class='template-date'
              :style="{ color: setting.date.color,
                        top: setting.date.top * data.ratio * templateRatio + 'px',
                        left: setting.date.left * data.ratio * templateRatio + 'px',
                        transform: `scale(${ 0.01 * setting.date.fontSize * data.ratio * templateRatio })` }">
              <div
                :style="{ transform: `rotate(${ setting.date.r }deg)` }">{{ dateText }}</div>
            </div>
          </div>
        </div>
      </div>
    </preview-piece>
  </div>
</template>

<script>
import tools from '../lib/color-tools';
import previewPiece from './preview-piece.vue';
import dateFormat from '../lib/date-format';


export default {
  props: ['currentTab', 'setting', 'setting-panel', 'mosaic'],
  data() {
    return {
      postData: new Array(50),
    }
  },
  mounted() {
  },
  methods: {
    getRandomColor(index) {
      return '#' + tools.getHsvColorHex(index / 100);
    },
  },
  computed: {
    ieStyle() {
      const style = { width: this.settingPanel.templateWidthSize * this.previewRatio + 'px' }
      if (this.$u.isIE()) return style;
    },
    previewRatio() {
      return this.settingPanel.previewHeight / 1920;
    },
    templateRatio() {
      if (this.setting.type === 'single') return this.settingPanel.templateWidthSize / 1200;
      if (this.setting.type === 'double'
      || this.setting.type === 'double-photo_only'
      || this.setting.type === 'coupon') return this.settingPanel.templateWidthSize / 900;
    },
    templateHeightRatio() {
      if (this.setting.type === 'single') return 1 / 2 * 3;
      if (this.setting.type === 'double'
      || this.setting.type === 'double-photo_only'
      || this.setting.type === 'coupon') return 1 / 3 * 4;
    },
    currentTemplate() {
      if (this.settingPanel.templateIndex === undefined) {
        if(this.setting.templates[this.templateType].length) return this.setting.templates[this.templateType][0];
        else return {};
      } else {
        return this.setting.templates[this.templateType][this.settingPanel.templateIndex];
      }
    },
    currentTemplatesIndex() {
      // setTimeout(_=> console.log('this.setting.templates[this.templateType].length', this.setting.templates), 1000);
      // console.log('this.templateType', this.templateType);
      const len = this.setting.templates[this.templateType].length;
      let { templateIndex } = this.settingPanel;
      if (templateIndex === undefined) templateIndex = 0;
      
      const templatesIndex = []
      if (len === 1) {
        templatesIndex.push(0);
      } else {
        templatesIndex.push(this.$u.r(templateIndex, len));
        templatesIndex.push(this.$u.r(templateIndex + 1, len));
        templatesIndex.push(this.$u.r(templateIndex - 1, len));
      }
      return templatesIndex;
    },
    computedTemplatesFramesStyle() {
      const { templates } = this.setting;
      return templates[this.templateType].map(item => {
        return {
          backgroundImage: this.$toBgImg(item.url)
        }
      });
    },
    templatesFrames() {
      return this.currentTemplatesIndex.map(index => this.computedTemplatesFramesStyle[index]);
    },
    templatesImageStyle() {
      const { templates } = this.setting;
      if (this.currentTemplatesIndex.length) return this.currentTemplatesIndex.map(index => {
        const v = { 
          top: templates[this.templateType][index].y,
          left: templates[this.templateType][index].x,
          width: templates[this.templateType][index].w,
          height: templates[this.templateType][index].h,
          transform: `rotate(${ templates[this.templateType][index].r }deg) scale(0.99975)`
        };
        return this.$u.map(v, item => item * this.templateRatio * this.previewRatio + 'px', 'transform');
      });
    },
    dateText() {
      if (this.setting.useDate) {
        return dateFormat.toString(this.setting.date.text);
      } else {
        return '';
      }
    },
    stickerPanelStyle() {
      const v = {
        width: this.setting.stickerPanel.width,
        height: this.setting.stickerPanel.height,
        'border-radius': this.setting.stickerPanel.height,
        'margin-top': this.setting.stickerPanel.marginTop
      };
      return this.$u.map(v, item => item * this.previewRatio + 'px');
    },
    stickerImageStyle() {
      const v = {
        width: this.setting.stickerPanel.height,
        height: this.setting.stickerPanel.height,
      };
      return this.$u.map(v, item => item * this.previewRatio - 10 + 'px');
    },
    printButtonStyle() {
      const v = {
        width: this.setting.printButton.w,
        height: this.setting.printButton.h,
        left: this.setting.printButton.x,
        top: this.setting.printButton.y
      };
      return this.$u.map(v, item => item * this.previewRatio + 'px');
    },
    templateType() {
      return this.setting.type;
    }
  },
  components: {
    previewPiece
  }
}
</script>

<style lang="scss">
  .print-button-panel {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 12;
  }
  .print-btn {
    position: relative;
    div {
      display: inline-block;
      position: absolute;
      width: 100%;
      height: 100%;
      background: no-repeat center / cover;

      &:hover {
        filter: brightness(104%);
      }
      &:active {
        filter: brightness(96%);
      }
    }
  }
  .sticker-layer {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    z-index: 11;
  }
  .sticker-panel{
    position: relative;
    background: white;
    padding: 5px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
  .sticker-image {
    display: inline-block;
    background: no-repeat center / contain;
  }
  .template-layer {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;

    .template {
      display: block;
      position: absolute;
      margin: 0 auto;
      overflow: hidden;
      animation-name: blinking;
      animation-duration: 0.3s;
      animation-iteration-count: infinite;
      &:nth-child(1) {
        z-index: 10;
      }
      &:nth-child(2) {
        &:before {
          content: '';
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.5);
          display: block;
          position: absolute;
          z-index: 11;
        }
        transform: perspective(500px) translateX(100px) translateZ(-150px) rotateY(-30deg);
      }
      &:nth-child(3) {
        &:before {
          content: '';
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.5);
          display: block;
          position: absolute;
          z-index: 11;
        }
        transform: perspective(500px) translateX(-100px) translateZ(-150px) rotateY(30deg);
      }
    }
    .template-image {
      position: absolute;
      background: no-repeat center / cover;
      background-image: url(http://placehold.jp/1040x1040.png);
      z-index: 2;
    }
    .template-frame {
      position: absolute;
      background: no-repeat 0 0 / cover;
      width: 100%;
      height: 100%;
      z-index: 3;
    }
    .template-date {
      display: inline-block;
      position: absolute;
      z-index: 3;
      white-space: nowrap;
      line-height: 1em;
      font-size: 100px;
      transform-origin: 0 0;
    }
  }
  @keyframes blinking {
    0% { background: red; }
    49% { background: red; }
    50% { background: blue; }
    100% { background: blue; }
  }
</style>

