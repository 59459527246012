export default {
  rgbToHex(r, g, b) {
    let R = r.toString(16),
      G = g.toString(16),
      B = b.toString(16);
      R = R.length === 1 ? '0' + R : R;
      G = G.length === 1 ? '0' + G : G;
      B = B.length === 1 ? '0' + B : B;
    return R + G + B;
  },
  hsvToRgb(h, s, v) {
    var r, g, b, i, f, p, q, t;
    if (arguments.length === 1) {
        s = h.s, v = h.v, h = h.h;
    }
    i = Math.floor(h * 6);
    f = h * 6 - i;
    p = v * (1 - s);
    q = v * (1 - f * s);
    t = v * (1 - (1 - f) * s);
    switch (i % 6) {
        case 0: r = v, g = t, b = p; break;
        case 1: r = q, g = v, b = p; break;
        case 2: r = p, g = v, b = t; break;
        case 3: r = p, g = q, b = v; break;
        case 4: r = t, g = p, b = v; break;
        case 5: r = v, g = p, b = q; break;
    }
    return {
        r: Math.round(r * 255),
        g: Math.round(g * 255),
        b: Math.round(b * 255)
    };
  },
  getHsvColorHex(h, s = 0.72, v = 0.90) {
    const { r, g, b } = this.hsvToRgb(h, s, v);
    return this.rgbToHex(r, g, b);
  }
}