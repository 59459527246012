const util = {
  install(Vue) {
    const util = {
      map(obj, f, ...exceptionKeys) {
        return Object.keys(obj).reduce((accumulator, value) => {
          if (exceptionKeys.indexOf(value) === -1) accumulator[value] = f(obj[value], value);
          else accumulator[value] = obj[value];
		      return accumulator;
        }, {});
      },
      r(v, len) {
        return (len + (v % len)) % len;
      },
      isIE() {
        const sAgent = window.navigator.userAgent;
        const Idx = sAgent.indexOf("MSIE");
  
        if (Idx > 0) return parseInt(sAgent.substring(Idx+ 5, sAgent.indexOf(".", Idx)));
        else if (!!navigator.userAgent.match(/Trident\/7\./)) return 11;
        else return null;
      },
      equal(o1, o2, { size = true, except = [] } = {}) {
        const o1_props = Object.getOwnPropertyNames(o1);
        const o2_props = Object.getOwnPropertyNames(o2);
        if (size && o1_props.length !== o2_props.length) {
          return false;
        }
        
        const props = (o1_props.concat(o2_props)).sort().reduce((a, b) => {if (a.slice(-1)[0] !== b) a.push(b); return a;}, []);
        for (const key of props) {
          if (except.indexOf(key) === -1 && o1[key] !== o2[key]) {
            return false;
            break;
          }
        }
        return true;
      },
      deassign(obj, ...keys) {
        const r = {};
        for (const key of keys) {
          if (typeof key === 'object') {
            for (const name in key) {
              if (obj[key[name]] !== undefined) r[name] = obj[key[name]];
            }
          } else {
            if (obj[key] !== undefined) r[key] = obj[key];
          }
        }
        return r;
      }
    }
    Vue.prototype.$u = util;
  }
};

export default util;