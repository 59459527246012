<template>
  <div
    class='hs-color-simple'
    @click="isOpen = !isOpen">
    <div
      class='color-preview'
      :style="{ background: colorHex }"></div>
    <input
      type='text'
      maxlength='7'
      :value="value"
      @input="inputWrap"
      @blur="returnValue"
      />
    
    <transition name="fade-custom-color">
      <div class='color-panel' v-show='isOpen'>
        <div
          class='color-piece'
          v-for="(_, index) of paletteLength"
          :style="{ background: getColor(index) }"
          @click="setColor(index)"
          :key="_">
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import tools from '../lib/color-tools';
export default {
  model: {
    event: 'change'
  },
  props: {
    id: {
      type: String,
      default: function () {
        return `y-color-${ this._uid }`;
      }
    },
    value: String
  },
  data() {
    return {
      isOpen: false,
      paletteLength: new Array(11)
    }
  },
  methods: {
    inputWrap(e) {
      e.target.value = e.target.value.replace(/[^#0-9a-fA-F]/g, '').slice(0, 7);
      if (!e.target.value.length) e.target.value = '#';
      if (e.target.value[0] !== '#') e.target.value = '#' + e.target.value;
      if (e.target.value.match(/#/g).length > 1) e.target.value = '#' + e.target.value.replace(/#/g, '');

      const len = e.target.value.length;
      if (len === 4 || len === 7) this.$emit('change', e.target.value);
    },
    returnValue(e) {
      e.target.value = this.value;
    },
    getColor(index) {
      return '#' + tools.getHsvColorHex(index / 12);
    },
    setColor(index) {
      this.$emit('change', '#' + tools.getHsvColorHex(index / 12));
    }
  },
  computed: {
    colorHex() {
      return this.value;
    }
  }
}
</script>

<style lang="scss" scoped>
  .fade-custom-color-enter-active,	.fade-custom-color-leave-active {
    transition: opacity .2s, transform .2s;
    transform-origin: top;
  }
  .fade-custom-color-enter, .fade-custom-color-leave-to	{
    transform: scaleY(0);
    opacity: 0;
  }
  .hs-color-simple {
    position: relative;
    display: inline-block;
    input {
      width: 100%;
      border: 1px solid #ccc;
      padding: 5px;
      padding-left: 30px;
      border-radius: 5px;
      box-sizing: border-box;
      background: white;
    }
  }
  .color-preview {
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
    left: 5px;
    width: 13px;
    height: 13px;
    border: 2px solid #2f1001;
    border-radius: 3px;
  }
  .color-panel {
    position: absolute;
    padding: 5px;
    background: #f6f6f6;
    border: 1px solid #ccc;
    z-index: 3;
    text-align: center;
    line-height: 1em;
    width: 100%;
    box-sizing: border-box;
  }
  .color-piece {
    display: inline-block;
    width: 13px;
    height: 13px;
    margin-left: 2px;
    &:hover {
      transform: scale(1.4);
      border: 1px solid #2f1001;
      border-radius: 1px;
      box-sizing: border-box;

    }
    transition: 0.2s;
  }
</style>
