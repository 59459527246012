<template>
  <li
    :class="{ 'current-tab': tabName === currentTab, [tabName]: true }"
    @click="link(tabName)">
    {{ getMenuName }}
  </li>
</template>

<script>
import routes from './tab-nav-routes';
export default {
  props: ['tabName', 'currentTab'],
  data() {
    return {
      routes
    }
  },
  methods: {
    link(tabName) {
      this.$emit('link', tabName);
    }
  },
  computed: {
    getMenuName() {
      return routes[this.tabName].name;
    },
    routeImageURL() {
      if (this.tabName === this.currentTab) {
        return routes[this.tabName].icon.slice(0, -4) + '_w.svg';
      } else {
        return routes[this.tabName].icon;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .intro {
    background-image: url(/../../assets/icon/pointerhand.svg);
  }
  .postView {
    background-image: url(/../../assets/icon/main.svg);
  }
  .template {
    background-image: url(/../../assets/icon/template.svg);
  }
  .printing {
    background-image: url(/../../assets/icon/project_request.svg);
  }
  .printEnd {
    background-image: url(/../../assets/icon/project_request.svg);
  }
  .custom {
    background-image: url(/../../assets/icon/custom.svg);
  }
  .event {
    background-image: url(/../../assets/icon/event.svg);
  }
  .langPack {
    background-image: url(/../../assets/icon/language.svg);
  } 
  .mosaic { 
    background-image: url(/../../assets/icon/mosaic.svg);
  }
  .current-tab {
    &.intro {
      background-image: url(/../../assets/icon/pointerhand_w.svg);
    }
    &.postView {
      background-image: url(/../../assets/icon/main_w.svg);
    }
    &.template {
      background-image: url(/../../assets/icon/template_w.svg);
    }
    &.printing {
      background-image: url(/../../assets/icon/project_request_w.svg);
    }
    &.printEnd {
      background-image: url(/../../assets/icon/project_request_w.svg);
    }
    &.custom {
      background-image: url(/../../assets/icon/custom_w.svg);
    }
    &.event {
      background-image: url(/../../assets/icon/event_w.svg);
    }
    &.langPack {
      background-image: url(/../../assets/icon/language_w.svg);
    } 
    &.mosaic { 
      background-image: url(/../../assets/icon/mosaic_w.svg);
    }    
  }
</style>