<template>
  <div class='printer-setting-contents'>
    <div class='setting'
      :class="{ wide: !needPreview }">
      <button @click="$eventBus.$emit('init-tab')">Reset</button>
      <button @click="$eventBus.$emit('undo-tab')">Undo</button>
        <tab-setting
          :current-tab="currentTab"
          :printer-setting="printerSetting"
          :setting-panel="settingPanel"
          :project-uid="projectUid"
          :settings-uid="settingsUid"></tab-setting>
      <button class='main-button' @click="save">SAVE & APPLY</button>
    </div>
    <div class='preview' v-show="needPreview">
      <tab-preview
        :current-tab="currentTab"
        :printer-setting="printerSetting"
        :setting-panel="settingPanel"></tab-preview>
    </div>
  </div>
</template>

<script>
  import tabPreview from '../preview/tab-preview.vue';
  export default {
    props: ['current-tab', 'printer-setting', 'setting-panel', 'project-uid', 'settings-uid'],
    created() {
      // console.log(this.printerSetting.mosaic.mosaicImage);
      // setInterval(_=> console.log(this.printerSetting.mosaic.mosaicImage), 1000);
    },
    data() {
      return {
        dragOption: {
          draggable_move: (delta, _, el) => {
            // console.log('', el);
            el.scrollTo(0, el.scrollTop - delta.y);
          }
        }
      }
    },
    methods: {
      save() {
        this.$eventBus.$emit('save-apply');
      }
    },
    computed: {
      scrollHeight() {
        console.log(this.currentTab);
        return 831;
      },
      needPreview() {
        // console.log('this.currentTab', this.currentTab);
        if (this.currentTab === 'langPack' || this.currentTab === 'mosaic') return false;
        else return true;
      }
    },
    components: {
      tabSetting: {
        props: ['current-tab', 'printer-setting', 'template-index', 'setting-panel', 'project-uid', 'settings-uid'],
        computed: {
          ViewComponent() {
            return require(`../tabs/${ this.currentTab }.vue`).default;
          }
        },
        render(h) {
          return h(this.ViewComponent);
        }
      },
      tabPreview
    }
  };
</script>

<style lang='scss'>
  .printer-setting-contents {
    position: relative;
    display: flex;
    align-items: start;
    justify-content: space-between;
    margin-top: 20px;
    
    .setting {
      width: percentage(1/3 * 1 + 0.03);
      padding-right: 20px;
      box-sizing: border-box;
      > button.main-button {
        cursor: pointer;
        width: 100%;
        height: 80px;
        color: white;
        border: 0;
        border-radius: 5px;
        background: #32b16c;
        margin-top: 20px;
      }
    }
    .setting.wide {
      width: 100%;
      padding-right: 0;
    }
    .setting-panel-scrollWrap {
      position: relative;
      .scrollbar {
        width: 5px;
        border-left: 5px solid #ff8400;
        position: absolute;
        right: 0;
      }
    }
    .setting-panel {
      background: white;
      border-radius: 5px;
      box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.5);
      height: 600px;
      overflow: hidden;
    }
    .setting-contents {
      .setting-title {
        padding: 10px 0 10px 20px;
        font-weight: bold;
        color: #ff8400;
        background: #eee;  
      }
      .setting-body {
        padding: 20px;
        padding-bottom: 50px;
      }
      p {
        margin: 20px 0;
      }
    }
    .preview {
      position: absolute;
      height: 100%;
      right: 0;
      width: percentage(1/3 * 2 - 0.03);
      background: white;
      box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.5);
    }
    .preview-panel {
      height: 700px;
    }
    .virtual-kiosk {
      position: relative;
      height: 650px;
      overflow: hidden;
    }
    .hr {
      border-bottom: 1px solid #ccc;
      margin: 20px 0;
    }
  }

.setting-panel.vb.vb-visible:before {
  content: '';
  background: linear-gradient(white 15%, rgba(255,255,255, 0));
  width: 100%;
  height: 50px;
  position: absolute;
  z-index: 1;
  pointer-events: none;
}

.setting-panel.vb.vb-visible:after {
  content: '';
  background: linear-gradient(rgba(255, 255, 255, 0), white 75%);
  width: 100%;
  height: 50px;
  bottom: 0;
  position: absolute;
  z-index: 1;
  pointer-events: none;
}

.vb > .vb-dragger {
    z-index: 5;
    width: 12px;
    right: 0;
}

.vb > .vb-dragger > .vb-dragger-styler {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transform: rotate3d(0,0,0,0);
    transform: rotate3d(0,0,0,0);
    -webkit-transition:
        background-color 100ms ease-out,
        margin 100ms ease-out,
        height 100ms ease-out;
    transition:
        background-color 100ms ease-out,
        margin 100ms ease-out,
        height 100ms ease-out;
    background-color: rgba(255, 132, 0, 0.1);
    margin: 5px 5px 5px 0;
    border-radius: 20px;
    height: calc(100% - 10px);
    display: block;
}

.vb.vb-scrolling-phantom > .vb-dragger > .vb-dragger-styler {
    background-color: rgba(255, 132, 0, 0.3);
}

.vb > .vb-dragger:hover > .vb-dragger-styler {
    background-color: rgba(255, 132, 0, 0.5);
    margin: 0px;
    height: 100%;
}

.vb.vb-dragging > .vb-dragger > .vb-dragger-styler {
    background-color: rgba(255, 132, 0, 0.5);
    margin: 0px;
    height: 100%;
}

.vb.vb-dragging-phantom > .vb-dragger > .vb-dragger-styler {
    background-color: rgba(255, 132, 0, 0.5);
}
</style>