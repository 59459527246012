import hsToasted from './hs-toasted.vue';

const Toasted = {
  install(Vue) {
    if (this.intalled) return;
    this.intalled = true;

    Vue.component('hs-toasted', hsToasted);
    // console.log(hsToasted);
  }
}

export default Toasted;