<template>
  <div class='setting-panel' v-bar>
    <div class='setting-contents'>
      <div class='setting-body'>
        <p>Language package supports up to 5 languages and you can edit message for each language.</p>
        <p>Select a language and edit Image / guide message for each language. Otherwise, default image / message will be shown to all pages.</p>
        <div class='hr'></div>
        <p><hs-select
            :options="langList"
            v-model="langListName"
            @changed="addLanguage"></hs-select></p>
        <table>
          <tr
            v-for="(tab, rowIndex) of tableTr" :key="tab">
            <td>{{ routes[tab] ? routes[tab].name : tab }}</td>
            <td
              v-for="(langPack, colIndex) of langPackData"
              :key="'langPack' + colIndex">
              <template v-if="langPack && langPack.lang">
                <!-- Title Columns -->
                <template v-if="rowIndex === 0 && colIndex !== 0"> 
                  {{ langPack.lang }}
                  <button class='delete-button' @click="removeLangPack(colIndex)"></button>
                </template>
                <!-- Content Columns -->
                <template v-else-if="langPack[tab] && rowIndex !== 0">
                  <!-- Default Language -->
                  <hs-file
                    v-if="colIndex === 0 && langPack[tab].backgroundImage"
                    v-model="langPack[tab].backgroundImage"
                    :guide="{ width: 1080, height: 1920 }"
                    :accept="'image/*'"
                    :preview="true"></hs-file>
                  <hs-file
                    v-else-if="colIndex === 0 && langPack[tab].url"
                    v-model="langPack[tab].url"
                    :guide="getGuide(tab)"
                    :accept="getAccept(tab)"
                    :preview="true"></hs-file>
                  <!-- Added Language -->
                  <hs-file
                    v-else
                    v-model="langPack[tab]"
                    :guide="getGuide(tab)"
                    :accept="getAccept(tab)"
                    :preview="true"></hs-file>
                </template>
                <template v-else-if="rowIndex !== 0">
                  <hs-file v-model="langPack[tab]" :preview="true"></hs-file>
                </template>
              </template>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
  import routes from '../layouts/tab-nav-routes';
  export default {
    data() {
      return {
        routes,
        langListName: 'Add language'
      };
    },
    methods: {
      addLanguage(langName) {
        if (langName === 'Add language') return;
        if (this.setting.data.length >= 4) {
          this.langListName = 'Add language';
          return this.$toastShow({
            type: 'error',
            title: 'Exceeded number of additional languages',
            content: `Can max number of additional languages is 4.`
          });
        }
        var mainLangData = this.langPackData[0];
        var newLang = {
          lang: langName,
          intro: mainLangData.intro.backgroundImage,
          postView: mainLangData.postView.backgroundImage,
          template: mainLangData.template.backgroundImage,
          printing: mainLangData.printing.backgroundImage,
          printEnd: mainLangData.printEnd.backgroundImage,
          custom: mainLangData.custom.backgroundImage,
        }
        for (var i = 0, item; item = this.templateNames[i]; i++) {
            newLang[item] = this.$fileToUrl(this.templates[i].url);
        }
        this.setting.data.push(newLang);
        this.langListName = 'Add language';
      },
      removeLangPack(index) {
        this.setting.data.splice(index - 1, 1);
      },
      getGuide(tabName) {
        if (tabName.search('template') !== -1) return { width: 1200, height: 1800 };
        else return { width: 1080, height: 1920 };
      },
      getAccept(tabName) {
        return 'image/*';
      }
    },
    computed: {
      setting() {
        return this.$parent.printerSetting[this.$parent.currentTab];
      },
      rootSetting() {
        return this.$parent.printerSetting;
      },
      templates() {
        return this.rootSetting.template.templates;
      },
      templateNames() {
        return Array.apply(null, Array(this.templatesLength)).map((_, index) => `template-${ index }`);
      },
      templatesLength() {
        return this.templates.length;
      },
      tableTr() {
        return [''].concat(this.rootSetting.tabFlow).concat(this.templateNames);
      },
      langPackData() {
        var mainLangData = [{
          lang: 'default',
          intro: this.rootSetting.intro,
          postView: this.rootSetting.postView,
          template: this.rootSetting.template,
          printing: this.rootSetting.printing,
          printEnd: this.rootSetting.printEnd,
          custom: this.rootSetting.custom,          
        }];
        for (var i = 0, item; item = this.templateNames[i]; i++) {
          mainLangData[0][item] = this.templates[i];
        }
        // console.log(mainLangData[0]);
        return mainLangData.concat(this.setting.data).concat(new Array(4 - this.setting.data.length));
      },
      langList() {
        const list = ['Add language', 'Arabic', 'Chinese', 'English', 'Hindi', 'Japanese', 'Korean', 'Portuguese', 'Russian', 'Spanish', 'Vietnamese'];
        // console.log(this.langPackData);
        for (const langPack of this.langPackData) {
          if (langPack) list.splice(list.indexOf(langPack.lang), 1);
        }
        return list;
      },
      
    },
  };
</script>

<style lang='scss' scoped>
  .detail-button, .delete-button {
    cursor: pointer;
    width: 27px;
    height: 27px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background: white;
    vertical-align: middle;
  }
  table {
    border: 1px solid #ccc;
    border-collapse: collapse;
  }
  td {
    border: 1px solid #ccc;
    text-align: center;
    width: 150px;
    height: 50px;
    padding: 15px;
    box-sizing: border-box;
  }
  dt, dd {
    margin-bottom: 20px;
    display: inline-block;
    vertical-align: middle;
  }
  dt {
    min-width: 100px;
    width: 30%;
  }
  dd {
    width: 60%;
  }
</style>