import Vue from 'vue';
import hsPrinterSettings from './layout.vue';

const [project_uid, printer_settings_uid] = location.pathname.replace(/(^\/|\/$)/, '').split('/');
const app = new Vue({
  el: '#printer-setting-panel',
  template: `
  <hs-printer-settings
    project_uid="${ project_uid }"
    printer_settings_uid="${ printer_settings_uid }"></hs-printer-settings>
    `,
    // kiosk_name="${ kiosk_name }"
    // project
  components: {
    hsPrinterSettings
  }
});