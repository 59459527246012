<template>
  <div>
    <label 
      class='hs-input'
      :for="id">
      <input
        type='text'
        :id="id"
        :value="value"
        :maxlength="maxlength"
        :style="{ width: `${ maxlength / 2 + 0.5 }em` }"
        @input="inputWrapValue"
        @blur="returnValue"
        @keydown="keyControl"
        @keyup="keyRelease"
        />
      <div
        class='type'
        v-drag="dragOption"
        ><slot></slot></div>
    </label>
  </div>
</template>

<script>
// import draggable from '../lib/draggable';
let isShift = false;

export default {
  model: {
    event: 'change'
  },
  props: {
    id: {
      type: String,
      default: function () {
        return `y-number-${ this._uid }`;
      }
    },
    maxlength: {
      type: Number,
      default: 1
    },
    value: Number,
    min: Number,
    max: Number
  },
  data() {
    return {
      dragOption: {
        draggable_move: delta => {
          this.wrapedValue(Number(this.value) - delta.y);
        }
      },
      savedValue: this.value
    }
  },
  methods: {
    inputWrapValue(e) {
      const v = Number(e.target.value);
      if (v >= this.min && v <= this.max) this.wrapedValue(v); 
      e.preventDefault();
    },
    wrapedValue(v) {
      if (v < this.min) v = this.min;
      if (v > this.max) v = this.max;
      this.savedValue = v;
      this.$emit('change', v);
      this.$emit('changed', v);
    },
    returnValue(e) {
      e.target.value = this.savedValue;
    },
    keyControl(e) {
      if (e.key.length === 1 && '0123456789'.search(e.key) === -1) e.preventDefault();
      let v;
      if ('ShiftLeft' === e.code) {
        isShift = true;
      }
      if ('ArrowUp' === e.code) {
        e.preventDefault();
        v = Number(this.value) + (isShift ? 10 : 1);
        this.wrapedValue(v);
      } 
      if ('ArrowDown' === e.code) {
        e.preventDefault();
        v = Number(this.value) - (isShift ? 10 : 1);
        this.wrapedValue(v);
      } 
    },
    keyRelease(e) {
      if ('ShiftLeft' === e.code) {
        isShift = false;
      }
    }
  }
}
</script>


<style lang="scss" scoped>
  div {
    cursor: text;    
    display: inline-block;
  }
  label {
    cursor: text;
  }
  .hs-input {
    position: relative;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
    overflow: hidden;
    background: white;
  }
  input {
    padding: 5px 10px;
    border: 0;
    vertical-align: middle;
    text-align: center;
  }
  .type {
    cursor: s-resize;
    height: 27px;
    padding: 0 10px;
    border-left: 1px solid #ccc;
    background: #f3f3f3;
    vertical-align: middle;
  }
</style>

