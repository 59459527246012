<template>
  <div @click="selectFile">
    <input
      type='file'
      ref="fileInput"
      :accept="accept"
      :id="id"
      @change="syncValue"
      />
    <input type='text'
      v-model="value.name"
      @click.stop="_=>{}"
      />
    <button v-if="changeButton"></button>
  </div>
</template>

<script>
export default {
  model: {
    event: 'change'
  },
  props: {
    id: {
      type: String,
      default: function () {
        return `y-list-file-${ this._uid }`;
      }
    },
    accept: {
      type: String,
      default: 'image/*'
    },
    value: {},
    changeButton: {
      type: Boolean
    },
    guide: Object
  },
  methods: {
    selectFile(e) {
      this.$refs.fileInput.click();
    },
    syncValue(e) {
      if (!e.target.files.length) return;
      const self = this;
      const fileType = e.target.files[0].type.split('/')[0];
      
      if (this.accept.search(fileType) === -1) return this.errorAlarm(this.accept);

        const img = new Image();
        img.onload = _=> {
          if (this.guide && !this.guideCheck(img)) {
            this.$toastShow({
              type: 'warn',
              title: 'Size does not fit the guide',
              content: `If the size does not match the guide, the image may be distorted.
(Recommended size: ${ JSON.stringify(this.guide).slice(1, -1).replace(/"/g, '') })`,
              // infinity: true
            });
          }
          const value = {
            ...this.value,
            name: e.target.files[0].name,
            url: e.target.files[0]
          }
          self.$emit('change', value);
          self.$emit('changed');
        };
        img.onerror = e => this.errorAlarm(this.accept);
        img.src = URL.createObjectURL(e.target.files[0]);
    },
    errorAlarm(type) {
      type = type.split(',').map(item => item.trim().split('/')[0]).join(' or ');
      this.$toastShow({
        type: 'error',
        title: 'Not Available File',
        content: `Only ${ type } files can be uploaded.`,
        // infinity: true
      });
      console.error('Not available file');
    },
    guideCheck(obj) {
      for (const key in this.guide) {
        if (obj[key] !== this.guide[key]) {
          return false;
        }
      }
      return true;
    }
  }
}
</script>

<style lang="scss" scoped>
  div {
    display: inline-block;    
  }
  input[type="file"] {
    display: none;
  }
  input[type="text"] {
    border: 0;
    outline: none;
    padding: 5px;

    &:focus {
      border: 1px solid #ccc;
      border-radius: 5px;
      
    }
  }
  button {
    cursor: pointer;
    width: 27px;
    height: 27px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background: white;
    vertical-align: middle;
    background: url(/../../assets/icon/baseline-add-24px.svg);
  }
</style>
