import { IMAGE_TO_CANVAS_RATIO, PHOTO_WIDTH_RATIO, PHOTO_HEIGHT_RATIO } from '../default.setting';

const createSVG = (attrsObj, parent) => {
  const el = document.createElementNS("http://www.w3.org/2000/svg", "svg");
  el.setAttributeNS("http://www.w3.org/2000/xmlns/", "xmlns:xlink", "http://www.w3.org/1999/xlink");
  attrs(el, attrsObj);
  if (parent) parent.appendChild(el);
  return el;
};

const attrs = (el, v) => {
  if (typeof v === 'object') {
    for (const key in v) {
      el.setAttribute(key, v[key]);
    }
  }
  else if (typeof v === 'string') {
    return el.getAttribute(v);
  }
};

const match = (value, obj) => {
  return obj[value];
};

// const MOSAIC_WIDTH = 2000;
const PHOTO_WEIGHT = 28.3464;
// const PHOTO_WEIGHT = 2.8346456693;
const ORIGIN_WEIGHT = PHOTO_WEIGHT / 28.3464;


class MosaicImage {
  constructor() {
    this.canvas = document.createElement('canvas');
    this.ctx = this.canvas.getContext('2d');
  }
  getMosaicSize(mosaicWidthCm, mosaicHeightCm) {
    const itc = IMAGE_TO_CANVAS_RATIO;
    const columns = Math.floor(mosaicWidthCm / (PHOTO_WIDTH_RATIO * itc));
    const rows = Math.floor(mosaicHeightCm / (PHOTO_HEIGHT_RATIO * itc));
    return { columns, rows };
  }
  getViewBox({columns, rows, ppi = 300} = {}) {
    // return { viewBoxWidth: columns * PHOTO_WIDTH * ppi,
    //         viewBoxHeight: rows * PHOTO_HEIGHT * ppi }
    return { viewBoxWidth: columns * PHOTO_WIDTH_RATIO,
      viewBoxHeight: rows * PHOTO_HEIGHT_RATIO };
  }
  getBase64FromURL(URL, svgWidth, svgHeight, mosaicCols, mosaicRows, mosaicTop, mosaicLeft, fileName = "image", { toastShow, blockOff } = {}) {
    
      const img = new Image();
      img.crossOrigin = "Anonymous";
      img.onload = _=> {
        try {
          this.canvas.width = img.width;
          this.canvas.height = img.height;

          this.ctx.drawImage(img, 0, 0);
          const imageBase64 = this.canvas.toDataURL();
          this.mkTemplate(svgWidth, svgHeight, imageBase64, img.width, img.height, mosaicCols, mosaicRows, mosaicTop, mosaicLeft, fileName, blockOff);
        } catch (e) {
          toastShow({
            type: 'error',
            title: 'Mosaic image save failed',
            content: `Mosaic image save failed. please try again.`
          });
          blockOff();
        }
      };
      img.onerror = _=> {
        toastShow({
          type: 'error',
          title: 'Mosaic image save failed',
          content: `Mosaic image save failed. please try again.`
        });
        blockOff();
      }
      img.src = URL;
  }
  mkTemplate(svgPlainWidth, svgPlainHeight, imageBase64, imageWidth, imageHeight, mosaicCols, mosaicRows, mosaicPlainTop, mosaicPlainLeft, fileName, blockOff) {
    // const mosaicSize = this.getMosaicSize(mosaicWidthCm, mosaicHeightCm);
    // const {columns, rows} = mosaicSize;
    const itc = IMAGE_TO_CANVAS_RATIO;
    const svgWidth = svgPlainWidth * PHOTO_WEIGHT;
    const svgHeight = svgPlainHeight * PHOTO_WEIGHT;

    const mosaicWidth = mosaicCols * PHOTO_WIDTH_RATIO * itc * PHOTO_WEIGHT;
    const mosaicHeight = mosaicRows * PHOTO_HEIGHT_RATIO * itc * PHOTO_WEIGHT;

    const mosaicTop = mosaicPlainTop * PHOTO_WEIGHT;
    const mosaicLeft = mosaicPlainLeft * PHOTO_WEIGHT;

    const ASPECT_RATIO = (mosaicCols * PHOTO_WIDTH_RATIO) / (mosaicRows * PHOTO_HEIGHT_RATIO);
    
    const svgWrap = body => `<svg xmlns:xlink="http://www.w3.org/1999/xlink"
                              width="${ svgWidth }"
                              height="${ svgHeight }"
                              viewBox="0 0 ${ svgWidth } ${ svgHeight }">${ body }</svg>`;
    
    const groupWrap = (body, id) => `<g id=${ id }>${ body }</g>`;
    
    const getAxis = (imageWidth, imageHeight) => {
      const SVG_ASPECT_RATIO = svgWidth / svgHeight;
      const IMAGE_ASPECT_RATIO = imageWidth / imageHeight;

      if (SVG_ASPECT_RATIO > IMAGE_ASPECT_RATIO) return 'width';
      else return 'height';
    };
    const base64MIMEWrap = data => {
      let payload = data.slice(22);
      let cursor = 0;
      let result = '';
      while (true) {
        if (payload[cursor]) {
          result += payload.substr(cursor, 76) + '\n';
          cursor += 76;
        } else {
          break;
        }
      }
      return 'data:image/png;base64,' + result;
    };
    const imageFile = (base64, imageWidth, imageHeight) => {
      
      const axis = getAxis(imageWidth, imageHeight);
      const MIN_RATIO = match(axis, {
        width: _=> {
          return svgWidth / imageWidth;
        },
        height: _=> {
          return svgHeight / imageHeight;
        }
      })();

      
      const computedWidth = imageWidth * MIN_RATIO;
      const computedHeight = imageHeight * MIN_RATIO;
      // console.log('MIN_RATIO', MIN_RATIO, 'SVG_ASPECT_RATIO', ASPECT_RATIO, 'IMAGE_ASPECT_RATIO' , imageWidth / imageHeight, 'axis', axis, 'svgWidth', svgWidth, 'svgHeight', svgHeight, 'computedWidth', computedWidth, 'computedHeight', computedHeight);
      return `<g id="yy-mask">
        <defs>
          <rect id="SVGID_1_" width="${ svgWidth }" height="${ svgHeight }"/>
        </defs>
        <clipPath id="SVGID_2_">
          <use xlink:href="#SVGID_1_"  style="overflow:visible;"/>
        </clipPath>
        <g id="yy-file" class="clip-path">
          <image style="overflow:visible;"
            width="${ imageWidth }"
            height="${ imageHeight }" 
            xlink:href="${ base64MIMEWrap(base64) }"
            transform="matrix(${ MIN_RATIO } 0
                          0 ${ MIN_RATIO }
                          ${ axis === 'height' ? (svgWidth - computedWidth) / 2 : 0 }
                          ${ axis === 'width' ?  (svgHeight - computedHeight) / 2 : 0 }
                        )"></image>
        </g>
      </g>\n`;
    };
    
    const tableGroup = (width, height, columns, rows) => {
      // console.log(width, height, columns, rows);
      let table = '';
      for (let i = 0; i <= columns; i++) {
        table += `<line class="line" x1="${ width / columns * i + mosaicLeft }" y1="${ mosaicTop }" x2="${ width / columns * i + mosaicLeft }" y2="${ height + mosaicTop }"/>\n`;
      }
      for (let i = 0; i <= rows; i++) {
        table += `<line class="line" x1="${ mosaicLeft }" y1="${ height / rows * i + mosaicTop }" x2="${ width + mosaicLeft }" y2="${ height / rows * i + mosaicTop }"/>\n`;
      }
      return groupWrap(table, 'yy-table');
    };

    const textGroup = (width, height, columns, rows) => {
      const alphaNums = n => {
        if (n === 0) return;
        const nums = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
        let total = n;
        let result = '';
        do {
          let r = (total - 1) % 26;
          result = nums[r] + result;
        } while (total = Math.floor((total - 1) / 26))
        return result;
      }
      const offsetX = 15 * ORIGIN_WEIGHT;
      const offsetY = 40 * ORIGIN_WEIGHT;
      let text = '';
      for (let i = 0; i < columns; i++) {
        for (let j = 0; j < rows; j++) {
          text += `<text transform="matrix(1 0 0 1 ${ width / columns * i + offsetX + mosaicLeft } ${ height / rows * j + offsetY + mosaicTop })" class="font">${ alphaNums(j + 1) + (i + 1) }</text>\n`
        }
      }
      return groupWrap(text, 'yy-text');
    };
    let body = '';
    body += `<style type="text/css">
                .clip-path{clip-path:url(#SVGID_2_);}
                .line{fill:none;stroke:#000000;stroke-miterlimit:10; stroke-width: ${ 1 * ORIGIN_WEIGHT }px;}
                .font{font-family:'NanumGothic';font-size: ${ 25 * ORIGIN_WEIGHT }px;}
                .fader{fill: #fff; opacity: 0.5;}
                .guide-line-border{fill:none;stroke:#FFFFFF;stroke-width: ${ 40 * ORIGIN_WEIGHT}pt;stroke-miterlimit:10;}
                .guide-line{fill:none;stroke:#000000;stroke-width: ${ 20 * ORIGIN_WEIGHT }pt;stroke-miterlimit:10;}
                .guide-font{font-family:'NanumGothic';stroke:#FFFFFF;stroke-width: ${ 10 * ORIGIN_WEIGHT };stroke-miterlimit:10;}
              </style>`;
    body += imageFile(imageBase64, imageWidth, imageHeight);
    body += `<rect id="yy-fader" class="fader line" x="${ mosaicLeft }" y="${ mosaicTop }" width="${ mosaicWidth }" height="${ mosaicHeight }"/>`;
    body += tableGroup(mosaicWidth, mosaicHeight, mosaicCols, mosaicRows);
    body += textGroup(mosaicWidth, mosaicHeight, mosaicCols, mosaicRows);

    const GUIDE_DIST = 100 * ORIGIN_WEIGHT;
    const GUIDE_LINE = 700 * ORIGIN_WEIGHT;
    const GUIDE_FONT_SIZE = 380 * ORIGIN_WEIGHT;
    this.ctx.font = `${ GUIDE_FONT_SIZE }px NanumGothic`;
    const GUIDE_W_VALUE_WIDTH = this.ctx.measureText(svgWidth + 'px').width;
    const GUIDE_H_VALUE_WIDTH = this.ctx.measureText(svgHeight + 'px').width;
    // top left bottom right
    body += `
    <g id="yy-guide">
      <path class="guide-line-border" d="M 0 -${ GUIDE_DIST } V -${ GUIDE_LINE } H ${ svgWidth } V -${ GUIDE_DIST }"/>
      <path class="guide-line" d="M 0 -${ GUIDE_DIST } V -${ GUIDE_LINE } H ${ svgWidth } V -${ GUIDE_DIST }"/>
      <path class="guide-line-border" d="M -${ GUIDE_DIST } 0 H -${ GUIDE_LINE } V ${ svgHeight } H -${ GUIDE_DIST }"/>
      <path class="guide-line" d="M -${ GUIDE_DIST } 0 H -${ GUIDE_LINE } V ${ svgHeight } H -${ GUIDE_DIST }"/>
      <text class="guide-font" style="font-size: ${ GUIDE_FONT_SIZE }px" transform="matrix(1 0 0 1 ${ svgWidth / 2 } ${ - GUIDE_LINE - GUIDE_FONT_SIZE })">${ svgPlainWidth }cm</text>
      <text class="guide-font" style="font-size: ${ GUIDE_FONT_SIZE }px" transform="matrix(${ Math.cos(Math.PI / 2) } ${ -Math.sin(Math.PI / 2) } ${ Math.sin(Math.PI / 2) } ${ Math.cos(Math.PI / 2) } ${ - GUIDE_LINE - GUIDE_FONT_SIZE } ${ svgHeight / 2 })">${ svgPlainHeight }cm</text>
    </g>`;
    //<path class="guide" d="M 0 ${ svgHeight + GUIDE_DIST } V ${ svgHeight + GUIDE_LINE } H ${ svgWidth } V ${ svgHeight + GUIDE_DIST }"/>
    //<path class="guide" d="M ${ svgWidth + GUIDE_DIST } 0 H ${ svgWidth + GUIDE_LINE } V ${ svgHeight } H ${ svgWidth + GUIDE_DIST }"/>
    // console.log(svgWrap(body));

    // - GUIDE_W_VALUE_WIDTH / 2
    // + GUIDE_H_VALUE_WIDTH / 2
    const svg = createSVG({
      id: 'yy-mosaic',
      width: svgWidth,
      height: svgHeight,
      viewBox: `0 0 ${ svgWidth } ${ svgHeight }`
    });
    svg.innerHTML = body;
    this.bindURL(svg, fileName, blockOff);
  }
  bindURL(svg, fileName, blockOff) {
    const serializer = new XMLSerializer();
    const svgBlob = new Blob([serializer.serializeToString(svg)],{'type': "image/svg+xml"});
    const url = URL.createObjectURL(svgBlob);
    // console.log(svg, url);
    const aTag = document.createElement("a");
    aTag.href = url;
    // aTag.download = 'mosaic_placeholder.svg';
    aTag.download = `mosaic-${ fileName }.svg`;
    aTag.click();
    blockOff();
  }
}

const mosaicImage = new MosaicImage()

export default mosaicImage;