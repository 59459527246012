<template>
  <div class='tab-list'
    @dragenter.prevent="_=>{}"
    @dragover.prevent="rearrangeList">
    <transition-group name="flip-list">
      <div
        v-for="tab of value"
        :draggable="tab === 'custom'"
        :class="{ 'tab-custom': tab === 'custom' }"
        :key="tab">{{ routes[tab].name }}</div>
    </transition-group>
  </div>
</template>

<script>
import routes from '../layouts/tab-nav-routes';

function indexOfElement(el) {
	return [].indexOf.call(el.parentElement.children, el)
}

let isMoving = false;

export default {
  model: {
    event: 'change'
  },
  props: {
    value: {}
  },
  created() {
    // setTimeout(_=> {
    //   // this.value = ['intro', 'postView', 'template', 'custom', 'printing', 'printEnd'];
    //   console.log('change v array');
    //   this.$emit('change', ' ');
    // }, 4000);
  },
  data() {
    return {
      routes
    }
  },
  methods: {
    rearrangeList(e) {
      if (isMoving) return;
      if (e.target.classList.contains('tab-custom')) return;
      isMoving = true;
      var customIndex = this.value.indexOf('custom');
      var targetIndex = indexOfElement(e.target);

      var cloneValue = this.value.slice();
      cloneValue.splice(customIndex, 1);
      cloneValue.splice(targetIndex, 0, 'custom');
      this.$emit('change', cloneValue);
      // this.$emit('changed', cloneValue);
      setTimeout(_=> isMoving = false, 110);
    }
  },
  // computed: {
  //   tabFlowWarp() {
  //     return this.value.split(' ');
  //   }
  // }
}
</script>

<style lang="scss" scoped>
  .flip-list-move {
    transition: transform 0.1s;
  }
  .tab-list {
    text-align: center;
    border: 1px solid #ccc;
    border-radius: 5px;
    .tab-custom {
      cursor: s-resize;
      background: #eee;
    }
    div {
      padding: 10px;
    }
  }
</style>

