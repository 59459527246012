<template>
  <div class='setting-panel' v-bar>
    <div class='setting-contents'>
      <div class='setting-body'>
        <p>Ending is the full imaged page that let people know the photo is successfully printed.</p>
        <div class='hr'></div>
        <!-- <p>Duplicate settings.</p>
        <hs-select :options="['None', 'Kiosk 1']" v-model="setting.copy"></hs-select>       -->
        <hs-checkbox v-model="setting.skip">Skip this page.</hs-checkbox>      
      </div>
      <template v-if="!setting.skip">
        <div class='setting-title'>
          Layout
        </div>
        <div class='setting-body'>
          <p><b>Upload Image</b></p>
          <div>
            <dt>Background</dt>
            <dd>
              <hs-file
                style='width: 100%;'
                v-model="setting.backgroundImage"
                :guide="{ width: 1080, height: 1920 }"></hs-file>
            </dd>
          </div>
          <div>
            <hs-checkbox
              v-model="useMainBackground"
              @change="checkMainBackground(useMainBackground)">Use background image like main</hs-checkbox>
          </div>
        </div>
        <div class='setting-title'>
          Timing
        </div>
        <div class='setting-body'>
          <p><b>Transfer to Main after</b></p>
          <p><hs-number v-model="setting.outTime" :maxlength="1" :min="3" :max="20">sec</hs-number></p>
          <p><hs-checkbox v-model="setting.clickable">Clickable for go to Next Page</hs-checkbox></p>
        </div>
        <div class='setting-title'>
          Set event ending for winner
        </div>
        <div class='setting-body'>
          <p><hs-checkbox v-model="setting.usePrizeEffect">Use Prize Effect</hs-checkbox></p>
          <div v-show="setting.usePrizeEffect">
            <div>
              <dt>Ending Sound</dt>
              <dd><hs-file v-model="setting.prizeSound" :accept="'audio/*'"></hs-file></dd>
            </div>
            <div>
              <dt>Ending Image</dt>
              <dd><hs-file
                v-model="setting.prizeContent"
                :accept="'image/*, video/*'"
                :guide="{ width: 1080, height: 1920 }"></hs-file></dd>
            </div>
            <p><button
              @mouseenter="settingPanel.previewPrizeEffect = true"
              @mouseleave="settingPanel.previewPrizeEffect = false"
              >Preview</button></p>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        useMainBackground: false
      }
    },
    methods: {
      checkMainBackground(bool) {
        if (bool) {
          this.$background = this.setting.backgroundImage;
          this.setting.backgroundImage = this.$parent.printerSetting['postView'].backgroundImage;
        }
        else {
          this.setting.backgroundImage = this.$background;
        }
      }
    },
    computed: {
      setting() {
        return this.$parent.printerSetting[this.$parent.currentTab];
      },
      settingPanel() {
        // console.log('this.$parent.settingPanel[this.$parent.currentTab]', this.$parent.settingPanel[this.$parent.currentTab])
        return this.$parent.settingPanel;
      }
    },
  };
</script>

<style lang='scss' scoped>
  dt, dd {
    margin-bottom: 20px;
    display: inline-block;
    vertical-align: middle;
  }
  dt {
    min-width: 100px;
    width: 30%;
  }
  dd {
    width: 60%;
  }
</style>