<template>
	<div class='custom-calendar'>
		<i class="material-icons" @click='openCalendar'>&#xe8df;</i>
		<input type='text' readonly @click='openCalendar' :value='value'>
		
		<transition name="fade-custom-calendar">
			<div class='calendar' v-show='isOpen'>
				<div class='calendar-control'>
					<i class="material-icons" @click='moveCalendar(-1)'>&#xe5cb;</i>
					<div class='year'>{{ selectYear }}년</div>
					<div class='month'>{{ selectMonth }}월</div>
					<i class="material-icons" @click='moveCalendar(1)'>&#xe5cc;</i>
				</div>
				<div class='days days-head'>
					<span>일</span>
					<span>월</span>
					<span>화</span>
					<span>수</span>
					<span>목</span>
					<span>금</span>
					<span>토</span>
				</div>
				<div class='days'>
					<day-span
						v-for='(day, index) of days'
						:key='index'
						:monthOffset='day.monthOffset'
						:dayNum='day.num'
						v-on:selectDate='selectDate'
						:class='{ today: day.today}'>
					</day-span>
				</div>
				<div class='time-picker'>

				</div>
			</div>
		</transition>
	</div>
</template>

<script>
	export default {
    model: {
      event: 'change'
    },
    props: ['value'],
		data() {
			return {
				isOpen: false,
				isChange: false,
				selectYear: '',
				selectMonth: '',
				days: []
			}
		},
		methods: {
			today() {
				const now = new Date();
				return {
					y: now.getFullYear(),
					m: now.getMonth(),
					d: now.getDate(),
					h: now.getHours(),
					min: now.getMinutes(),
					s: now.getSeconds()
				};
			},
			getFirstDate(y, m) {
				let day = new Date(y, m, 1).getDay();
				if (day === 0) day = 7;
				return day;
			},
			getLastDate(y, m) {
				let lastDateOfMonth = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
				if ((y % 4 === 0 && y % 100 !== 0) || y % 400 === 0) lastDateOfMonth[1] = 29;
				return [ lastDateOfMonth[m], lastDateOfMonth[(12 + m - 1) % 12]];
			},
			moveCalendar(offset) {
				[ this.selectYear, this.selectMonth ] = this.ymWrap(this.selectYear, this.selectMonth + offset);
				this.GenCalendar(this.selectYear, this.selectMonth - 1, this.today().d);
			},
			GenCalendar(y, m, d, h, min, s) {
				this.isChange = true;
				this.selectYear = y;
				this.selectMonth = m + 1;
				
				const firstDate = this.getFirstDate(y, m);
				const [ lastDate, beforeLastDate ] = this.getLastDate(y, m);

				let rows = Math.ceil((firstDate + lastDate) / 7);
				if ((firstDate + lastDate) % 7 === 0) rows++;
				
				let dayCount = 1;
				
				this.days = [];
				for (let i = 0; i < rows * 7; i++) {
					if (firstDate > i) {
						this.days.push({
							num: beforeLastDate - firstDate + i + 1,
							monthOffset: -1
						});
					} else if (i >= firstDate + lastDate) {
						this.days.push({
							num: i - firstDate - lastDate + 1,
							monthOffset: 1
						});
					} else {
						let today, year, month, day;
						if (this.value) {
							[ year, month, day ] = this.value.split('-');
							year = Number(year);
							month = Number(month);
							today = d === dayCount && m + 1 === month && y === year;
						} else {
							today = d === dayCount && m === this.today().m && y === this.today().y;
						}
						this.days.push({
							num: dayCount++,
							monthOffset: 0,
							today
						});
					}
				}
				
				this.isOpen = true;
				setTimeout(_=> this.isChange = false, 500);
			},
			openCalendar() {
				if (this.isOpen) return this.isOpen = false;
				
				let y, m, d, h, min, s;
				if (!this.value) {
					({ y, m, d, h, min, s } = this.today());
				} else {
					
					[ y, m, d, h, min, s ] = this.value.split(/[- :]/g);
					y = Number(y);
					m = Number(m) - 1;
					d = Number(d);
					h = Number(h);
					min = Number(min);
					s = Number(s);
				}

				this.GenCalendar(y, m, d, h, min, s);
			},
			ymWrap(y, m) {
				if (m === 0) {
					m = 12;
					y--;
				} else if (m === 13) {
					m = 1;
					y++;
				}
				return [ y, m ];
			},
			selectDate(dateObj) {
				let year = this.selectYear;
				let month = this.selectMonth + dateObj.monthOffset;
				let { day } = dateObj;
				
				[ year, month ] = this.ymWrap(year, month);
				
				if (String(month).length === 1) {
					month = '0' + month;
				}
				
				if (String(day).length === 1) {
					day = '0' + day;
				}
				
				this.$emit('change', `${year}-${month}-${day}`);
				this.$emit('changed', `${year}-${month}-${day}`);
				this.isOpen = false;
			}
		},
		components: {
			daySpan: {
				props: ['monthOffset', 'dayNum'],
				template: `<span
										@click='selectDate'
										:class='{ notCurrentMonth: monthOffset !== 0 }'>
										{{ dayNum }}
									</span>`,
				methods: {
					selectDate() {
						this.$emit('selectDate', { day: this.dayNum, monthOffset: this.monthOffset });
					},
				}
			}
		}
	}

</script>


<style lang='scss' scoped>
	@import url(https://fonts.googleapis.com/icon?family=Material+Icons);
	.custom-calendar {
    position: relative;
		display: block;
		.fade-custom-calendar-enter-active,	.fade-custom-calendar-leave-active {
			transition: opacity .2s, transform .2s;
			transform-origin: top;
		}
		.fade-custom-calendar-enter, .fade-custom-calendar-leave-to	{
			transform: scaleY(0);
			opacity: 0;
		}
		input {
			cursor: pointer;
			display: inline-block;
			border: 1px solid #ccc;
			border-radius: 5px;
			padding: 5px;
			vertical-align: middle;
      width: 100%;
		}
		> i {
      position: absolute;
			cursor: pointer;
			display: inline-block;
			top: 0;
			right: -10px;
			line-height: 27px;
			color: #FF8400;
			vertical-align: middle;
		}
		.calendar-control {
			display: flex;
			cursor: default;
			align-items: center;
			justify-content: space-between;
			padding-bottom: 10px;
			i {
				cursor: pointer;
			}
		}
		.calendar {
			line-height: 1em;
			$width: 200px;
			$height: 200px;
			position: absolute;
			z-index: 4;
			width: $width;
/*			height: $height;*/
			padding: 10px 10px 15px 10px;
			background: #f6f6f6;
			border: 1px solid #ccc;
			.year, .month {
				display: inline-block;
			}
			.days {
				display: flex;
				flex-wrap: wrap;
				&.days-head {
					cursor: default;
					span {
						&:hover {
							background: transparent;
						}
					}
				}
				span {
					cursor: pointer;
					text-align: center;
					line-height: 2em;
					width: $width/7;
					&.today, &:active {
						color: white !important;
						background: #FF8400 !important;
					}
					&.notCurrentMonth {
						color: #ccc;
					}
					&:hover {
						color: black;
						background: #ddd;
					}
					&:nth-child(7n - 6) {
						color: crimson;
					}
					&.notCurrentMonth:nth-child(7n - 6) {
						color: pink;
					}
					&:nth-child(7n) {
						color: cornflowerblue;
					}
					&.notCurrentMonth:nth-child(7n) {
						color: lightblue;
					}
				}
			}

		}
	}

</style>
