import hsBlock from './hs-block.vue';

const hsBlockPlugin = {
  install(Vue) {
    if (this.intalled) return;
    this.intalled = true;

    Vue.component('hs-block', hsBlock);
    Vue.prototype.$blockOn = param => Vue.prototype.$eventBus.$emit('hsBlockOn', param);
    Vue.prototype.$blockOff = param => Vue.prototype.$eventBus.$emit('hsBlockOff', param);
  }
}

export default hsBlockPlugin;