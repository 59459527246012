<template>
  <div
    v-show="currentTab === 'event'">
    <preview-piece :background="templateSetting.backgroundImage" :setting-panel="settingPanel">
      <div 
        class='template-panel'
        slot-scope="data"
        :style="{ 'padding-top': templateSetting.paddingTop * data.ratio + 'px' }"
        >
        <div
          class='template'
          :style="{ width: settingPanel.templateWidthSize * data.ratio + 'px',
                    height: settingPanel.templateWidthSize * heightRatio * data.ratio + 'px' }">
          <div
            class='template-image'
            :style="templateImageStyle"></div>
          <div
            class='template-frame'
            :style="currentTemplate.url | toBackgroundImage"></div>
          <div
            class='template-stamp'
            :style="stampImageStyle(settingPanel.eventPrizeIndex)"></div>
        </div>
      </div>
    </preview-piece>
  </div>
</template>

<script>
import tools from '../lib/color-tools';
import previewPiece from './preview-piece.vue';
export default {
  props: ['currentTab', 'setting', 'template-setting', 'setting-panel'],
  data() {
    return {
      postData: new Array(50),
    }
  },
  methods: {
    getRandomColor(index) {
      return '#' + tools.getHsvColorHex(index / 100,);
    },
    stampImageStyle(eventPrizeIndex) {
      // eventIndex === -1 ? eventIndex = 0 : 0;
      eventPrizeIndex === -1 ? eventPrizeIndex = 0 : 0;

      if (this.setting.prizes.length) {
        return { 
          backgroundImage: this.$toBgImg(this.setting.prizes[eventPrizeIndex].url),
          top: this.setting.prizes[eventPrizeIndex].y * this.templateRatio * this.previewRatio + 'px',
          left: this.setting.prizes[eventPrizeIndex].x * this.templateRatio * this.previewRatio + 'px',
          width: this.setting.prizes[eventPrizeIndex].w * this.templateRatio * this.previewRatio + 'px',
          height: this.setting.prizes[eventPrizeIndex].h * this.templateRatio * this.previewRatio + 'px',
          transform: `rotate(${ this.setting.prizes[eventPrizeIndex].r }deg)`
        };
      }
    }
  },
  computed: {
    previewRatio() {
      return this.settingPanel.previewHeight / 1920;
    },
    templateRatio() {
      if (this.templateSetting.type === 'single') return this.settingPanel.templateWidthSize / 1200;
      if (this.templateSetting.type === 'double' || this.setting.type === 'double-photo_only') return this.settingPanel.templateWidthSize / 900;
    },
    heightRatio() {
      if (this.templateSetting.type === 'single') return 3 / 2;
      if (this.templateSetting.type === 'double' || this.setting.type === 'double-photo_only') return 4 / 3;
    },
    currentTemplate() {
      if (this.settingPanel.templateIndex === undefined) {
        if(this.templateSetting.templates[this.templateSetting.type].length) return this.templateSetting.templates[this.templateSetting.type][0];
        else return {};
      } else {
        return this.templateSetting.templates[this.templateSetting.type][this.settingPanel.templateIndex];
      }
    },
    templateImageStyle() {
      if (this.templateSetting.templates[this.templateSetting.type].length) return { 
        'background-image': 'url(http://placehold.jp/1040x1040.png)',
        top: this.currentTemplate.y * this.templateRatio * this.previewRatio + 'px',
        left: this.currentTemplate.x * this.templateRatio * this.previewRatio + 'px',
        width: this.currentTemplate.w * this.templateRatio * this.previewRatio + 'px',
        height: this.currentTemplate.h * this.templateRatio * this.previewRatio + 'px',
        transform: `rotate(${ this.currentTemplate.r }deg) scale(1.05)`
      };
    },
  },
  components: {
    previewPiece
  }
}
</script>

<style lang="scss">
  .template-panel {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;

    .template {
      position: relative;
      overflow: hidden;
      animation-name: blinking;
      animation-duration: 0.3s;
      animation-iteration-count: infinite;

      &-image {
        
        position: absolute;
        background: no-repeat center / cover;
        z-index: 2;
      }
      &-frame {
        position: absolute;
        background: no-repeat 0 0 / cover;
        width: 100%;
        height: 100%;
        z-index: 3;
      }
    }
  }
  .template-stamp {
    position: absolute;
    background: no-repeat center / cover;
    z-index: 4;
  }
  @keyframes blinking {
    0% { background: red; }
    49% { background: red; }
    50% { background: blue; }
    100% { background: blue; }
  }
</style>

