<template>
  <div v-show="currentTab === 'printEnd'">
    <preview-piece :background="setting.backgroundImage" :setting-panel="settingPanel">
      <div 
        class='prize-panel'
        :style="this.setting.prizeContent | toBackgroundImage"
        v-show="settingPanel.previewPrizeEffect"></div>
    </preview-piece>
    
  </div>
</template>

<script>
import previewPiece from './preview-piece.vue';
export default {
  props: ['current-tab', 'setting', 'setting-panel'],
  components: {
    previewPiece
  }
}
</script>

<style lang="scss" scoped>
  .prize-panel {
    width: 100%;
    height: 100%;
    background: no-repeat center / cover;
  }
</style>

