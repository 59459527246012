const toBgImg = {
  install(Vue) {
    const fileToUrl = v => {
      const data = v;
      if (data instanceof File) {
        return URL.createObjectURL(data);
      } else if (typeof data === 'string') {
        return data;
      }
    };
    const toBgImg = v => `url(${ fileToUrl(v) })`;
    Vue.prototype.$fileToUrl = fileToUrl;
    Vue.prototype.$toBgImg = toBgImg;
    Vue.filter('toBackgroundImage', function (v) {
      return { backgroundImage: toBgImg(v) };
    });
  }
};

export default toBgImg;