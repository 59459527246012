<template>
  <div v-show="currentTab === 'printing'">
    <preview-piece :background="setting.backgroundImage" :setting-panel="settingPanel">
      <div
        class='advertisement-panel'
        slot-scope="data"
        :style="{ 'padding-top': setting.paddingTop * data.ratio + 'px' }">
        <div class='advertisement'
          :style="{
            width: setting.advertisementWidth * data.ratio + 'px',
            height: setting.advertisementHeight * data.ratio + 'px',
          }">
          <div class='loading'>
            <div class='bar'
              :style="{ background: mainSetting.mainColor }"></div>
          </div>
          <video
            class='content'
            v-show="contentType === 'video'"
            :src="adVideoURL"
            autoplay mute loop></video>
          <div
            class='content'
            v-show="contentType === 'image'"
            :style="setting.advertisement | toBackgroundImage"></div>
        </div>
      </div>
    </preview-piece>
  </div>
</template>

<script>
import previewPiece from './preview-piece.vue';
export default {
  props: ['currentTab', 'setting', 'setting-panel', 'mainSetting'],
  computed: {
    contentType() {
      const ad = this.setting.advertisement;
      if (ad instanceof File) {
        console.log('this.setting.advertisement', this.setting.advertisement);
        if (ad.type.search('video') !== -1) return 'video';
        if (ad.type.search('image') !== -1) return 'image';
      } else {
        if ('mp4wmvflv'.search(ad.slice(-3)) !== -1) {
          return 'video';
        }
        if ('jpgjpeggifpng'.search(ad.slice(-3)) !== -1) {
          return 'image';
        }
      }
    },
    adVideoURL() {
      var video = this.setting.advertisement;
      return this.$fileToUrl(video);
    }
  }, 
  components: {
    previewPiece
  }
}
</script>

<style lang="scss" scoped>
  .advertisement-panel {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    
    .advertisement {
      position: relative;
      overflow: hidden;
      .loading {
        position: relative;
        width: 100%;
        height: 10px;
        background: white;
        z-index: 2;
        .bar {
          transform-origin: 0 0;
          width: 100%;
          height: 100%;
          // background: #FF8400;
          animation-name: loadingAnimation;
          animation-duration: 25s;
          animation-iteration-count: infinite;
        }
      }
      .content {
        position: relative;
        width: 100%;
        height: 100%;
        background: no-repeat center / cover;
        object-fit: cover;
      }
    }
  }
  @keyframes loadingAnimation {
    0% {
      transform: scaleX(0);
    }
    100% {
      transform: scaleX(1);
    }
  }
</style>

