const match = (v, obj) => obj[v];

// export const MODE = 'mosaic'; // * 'default', 'mosaic', 'divided4'
export const MODE = 'mosaic'; // * 'default', 'mosaic', 'divided4'
export const PHOTO_WIDTH = match(MODE, {
  mosaic: 7.62,
  divided4: 5.08,
});
export const PHOTO_HEIGHT = match(MODE, {
  mosaic: 10.16,
  divided4: 7.62,
});
export const IMAGE_TO_CANVAS_RATIO = 2.54;

// * inch
export const PHOTO_WIDTH_RATIO = match(MODE, {
  mosaic: 3,
  divided4: 2,
});
export const PHOTO_HEIGHT_RATIO = match(MODE, {
  mosaic: 4,
  divided4: 3,
});

export default {
  "_inited":true,
  "tabFlow":["intro","postView","template","printing","printEnd","custom"],
  "intro": {
    "copy": "None",
    "skip": false,
    "backgroundImage": "https://hashsnap-reports.s3.ap-northeast-2.amazonaws.com/uploaded-posts/341877ee-96f9-4131-beec-c61b0f80acfa.png",
    "inTime": 60,
    "outTime": 120
  },
  "postView": {
    "adminPassword": "7393",
    "mainColor": "#841c20",
    "copy": "None",
    "headerHeight": 250,
    "footerHeight": 100,
    "backgroundImage": "https://hashsnap-reports.s3.ap-northeast-2.amazonaws.com/uploaded-posts/7a4668b8-2d8d-4b2b-aa3a-56b798031b43.png",
    "paddingTop": 340,
    "cols": 4
  },
  "template": {
    "printButton": {
      "url": "https://hashsnap-reports.s3.ap-northeast-2.amazonaws.com/uploaded-posts/4155aa6a-b54e-4f66-b8fc-4c0f0fa1af13.png",
      "w": 150,
      "h": 150,
      "x": 465,
      "y": 1395
    },
    "copy": "None",
    "backgroundImage": "https://hashsnap-reports.s3.ap-northeast-2.amazonaws.com/uploaded-posts/f0e96923-365a-4bf2-80c0-c5d2bcf19fa0.png",
    "type": "single",
    "paddingTop": 550,
    "coupon": [{ "url":"https://hashsnap-reports.s3.ap-northeast-2.amazonaws.com/uploaded-posts/78116d03-52dc-499a-b423-5dcc6f453a7d.png" }],
    "templates": 
    {
      "single": [{
        "name": "템플릿-지그재그",
        "url": "https://aw3kr.s3.ap-northeast-2.amazonaws.com/uploaded-posts/a860c823-6323-4f55-9e05-8e4896f05e55.png",
        "w": 1200,
        "h": 1060,
        "x": 0,
        "y": 245,
        "r": 0
      }, {
        "name": "템플릿-새싹",
        "url": "https://aw3kr.s3.ap-northeast-2.amazonaws.com/uploaded-posts/1621f761-e437-47aa-9d1e-d1d731da9ac5.png",
        "w": 1005,
        "h": 1005,
        "x": 167,
        "y": 294,
        "r": 345
      }, {
        "name": "템플릿-블랙",
        "url": "https://aw3kr.s3.ap-northeast-2.amazonaws.com/uploaded-posts/5b36ac1d-42e7-4324-93c2-6e0467f2f672.png",
        "w": 1045,
        "h": 1045,
        "x": 75,
        "y": 250,
        "r": 0
      }],
      "double": [{
        "name": "템플릿-반분할",
        "url": "https://hashsnap-reports.s3.ap-northeast-2.amazonaws.com/uploaded-posts/776302e8-c631-45b3-a814-6dcfafc7ea9a.png",
        "w": 750,
        "h": 760,
        "x": 80,
        "y": 220,
        "r": 0
      }],
      "double-photo_only": [{
        "name": "템플릿-반분할",
        "url": "https://hashsnap-reports.s3.ap-northeast-2.amazonaws.com/uploaded-posts/776302e8-c631-45b3-a814-6dcfafc7ea9a.png",
        "w": 750,
        "h": 760,
        "x": 80,
        "y": 220,
        "r": 0
      }],
      "coupon": [{
        "name": "템플릿-반분할",
        "url": "https://hashsnap-reports.s3.ap-northeast-2.amazonaws.com/uploaded-posts/776302e8-c631-45b3-a814-6dcfafc7ea9a.png",
        "w": 750,
        "h": 760,
        "x": 80,
        "y": 220,
        "r": 0
      }],
    },
    "useDate": false,
    "date": {
      "text": "{YYYY}-{MM}-{DD}",
      "color": "#40e640",
      "fontSize": 80,
      "top": 1150,
      "left": 710,
      "r": 0
    },
    "useSticker": true,
    "stickers": [{
      "name": "안경",
      "url": "https://aw3kr.s3.ap-northeast-2.amazonaws.com/uploaded-posts/0b654751-2df3-4f23-96a0-45a4d643528b.png"
    }, {
      "name": "만세",
      "url": "https://aw3kr.s3.ap-northeast-2.amazonaws.com/uploaded-posts/4e1af8bb-5bc2-4125-a504-b7bb7d770e4e.png"
    }, {
      "name": "입술자국",
      "url": "https://aw3kr.s3.ap-northeast-2.amazonaws.com/uploaded-posts/08549f8e-4abf-4d9a-af3f-d424dc5fdc7b.png"
    }, {
      "name": "좋아요",
      "url": "https://aw3kr.s3.ap-northeast-2.amazonaws.com/uploaded-posts/93a82b77-39db-4daf-986b-c2967f2179ea.png"
    }, {
      "name": "LOL",
      "url": "https://aw3kr.s3.ap-northeast-2.amazonaws.com/uploaded-posts/936323d8-ce5e-4799-b11d-92d7e1b389d9.png"
    }, {
      "name": "모벰버",
      "url": "https://aw3kr.s3.ap-northeast-2.amazonaws.com/uploaded-posts/e30b56fa-f1f3-40c1-8ce0-c9fc15373ff7.png"
    }],
    "stickerPanel": {
      "width": 600,
      "height": 120,
      "marginTop": 390
    }
  },
  "printing": {
    "copy": "None",
    "backgroundImage": "https://hashsnap-reports.s3.ap-northeast-2.amazonaws.com/uploaded-posts/72297765-fc2a-40a2-a684-477538c082b2.png",
    "paddingTop": 620,
    "type": "image",
    "advertisement": "https://hashsnap-reports.s3.ap-northeast-2.amazonaws.com/uploaded-posts/f2db7ed8-55a5-40a6-821a-e256f0bb3032.png",
    "advertisementWidth": 813,
    "advertisementHeight": 508
  },
  "printEnd": {
    "copy": "None",
    "skip": false,
    "outTime": 5,
    "paddingTop": 275,
    "backgroundImage": "https://hashsnap-reports.s3.ap-northeast-2.amazonaws.com/uploaded-posts/9ed646f2-098d-4976-bdc2-ec5976238370.png",
    "clickable": true,
    "usePrizeEffect": true,
    "prizeSound": "https://aw3kr.s3.ap-northeast-2.amazonaws.com/uploaded-posts/2859074d-9bf6-4e2f-9356-df6749873123.mp3",
    "prizeContent": "https://hashsnap-reports.s3.ap-northeast-2.amazonaws.com/uploaded-posts/17cdf109-354f-40e5-82e2-f397612f4be7.png"
  },
  "custom": {
    "skip": true,
    "clickable": true,
    "type": "Passive Advertisting",
    "backgroundImage": "https://hashsnap-reports.s3.ap-northeast-2.amazonaws.com/uploaded-posts/12305a8d-2be7-44df-9d02-d9532214d3d0.png",
    "outTime": 5
  },
  "event": {
    "use": false,
    "copy": false,
    "last_id": 0,
    "stampImage": [],
    "prizes": [
      {
        "name": "stamp1.png",
        "url": "https://hashsnap-reports.s3.ap-northeast-2.amazonaws.com/uploaded-posts/724d5dd0-3b5c-49ed-96de-a4be98a372fa.png",
        "order": 10,
        "x": 850,
        "y": 1150,
        "w": 250,
        "h": 250,
        "r": 328
      }, {
        "name": "stamp2.png",
        "url": "https://hashsnap-reports.s3.ap-northeast-2.amazonaws.com/uploaded-posts/252c3665-8ac9-4261-9b26-5de26a4a34fa.png",
        "order": 10,
        "x": 850,
        "y": 1150,
        "w": 250,
        "h": 250,
        "r": 0
      }, {
        "name": "stamp3.png",
        "url": "https://hashsnap-reports.s3.ap-northeast-2.amazonaws.com/uploaded-posts/447ebe65-71c3-4c3f-b37f-78849c6faddb.png",
        "x": 850,
        "y": 1150,
        "w": 250,
        "h": 250,
        "r": 0
      }, {
        "name": "stamp4.png",
        "url": "https://hashsnap-reports.s3.ap-northeast-2.amazonaws.com/uploaded-posts/663052f1-be37-4e2e-99fb-b1e54f819f3d.png",
        "x": 850,
        "y": 1150,
        "w": 250,
        "h": 250,
        "r": 0
      }, {
        "name": "stamp5.png",
        "url": "https://hashsnap-reports.s3.ap-northeast-2.amazonaws.com/uploaded-posts/9d43a6b0-4650-4bb8-84ad-0db736d2fb9a.png",
        "x": 850,
        "y": 1150,
        "w": 250,
        "h": 250,
        "r": 0
      }, {
        "name": "stamp6.png",
        "url": "https://hashsnap-reports.s3.ap-northeast-2.amazonaws.com/uploaded-posts/724d5dd0-3b5c-49ed-96de-a4be98a372fa.png",
        "order": 10,
        "x": 850,
        "y": 1150,
        "w": 250,
        "h": 250,
        "r": 328
      }, {
        "name": "stamp7.png",
        "url": "https://hashsnap-reports.s3.ap-northeast-2.amazonaws.com/uploaded-posts/252c3665-8ac9-4261-9b26-5de26a4a34fa.png",
        "order": 10,
        "x": 850,
        "y": 1150,
        "w": 250,
        "h": 250,
        "r": 0
      }, {
        "name": "stamp8.png",
        "url": "https://hashsnap-reports.s3.ap-northeast-2.amazonaws.com/uploaded-posts/447ebe65-71c3-4c3f-b37f-78849c6faddb.png",
        "x": 850,
        "y": 1150,
        "w": 250,
        "h": 250,
        "r": 0
      }, {
        "name": "stamp9.png",
        "url": "https://hashsnap-reports.s3.ap-northeast-2.amazonaws.com/uploaded-posts/663052f1-be37-4e2e-99fb-b1e54f819f3d.png",
        "x": 850,
        "y": 1150,
        "w": 250,
        "h": 250,
        "r": 0
      }, {
        "name": "stamp10.png",
        "url": "https://hashsnap-reports.s3.ap-northeast-2.amazonaws.com/uploaded-posts/9d43a6b0-4650-4bb8-84ad-0db736d2fb9a.png",
        "x": 850,
        "y": 1150,
        "w": 250,
        "h": 250,
        "r": 0
      }
    ]
  },
  // "event": {
  //   "use": false,
  //   "copy": false,
  //   "last_id": 0,
  //   "stampImage": [],
  //   "events": [{
  //     "id": 3,
  //     "name": "event_1",
  //     "startTime": "2018-06-22 01:00:00",
  //     "endTime": "2018-06-22 09:00:00",
  //     "prizes": [{
  //       "name": "stamp1.png",
  //       "url": "https://hashsnap-reports.s3.ap-northeast-2.amazonaws.com/uploaded-posts/724d5dd0-3b5c-49ed-96de-a4be98a372fa.png",
  //       "order": 10,
  //       "x": 850,
  //       "y": 1150,
  //       "w": 250,
  //       "h": 250,
  //       "r": 328
  //     }, {
  //       "name": "stamp2.png",
  //       "url": "https://hashsnap-reports.s3.ap-northeast-2.amazonaws.com/uploaded-posts/252c3665-8ac9-4261-9b26-5de26a4a34fa.png",
  //       "order": 10,
  //       "x": 850,
  //       "y": 1150,
  //       "w": 250,
  //       "h": 250,
  //       "r": 0
  //     }, {
  //       "name": "stamp3.png",
  //       "url": "https://hashsnap-reports.s3.ap-northeast-2.amazonaws.com/uploaded-posts/447ebe65-71c3-4c3f-b37f-78849c6faddb.png",
  //       "x": 850,
  //       "y": 1150,
  //       "w": 250,
  //       "h": 250,
  //       "r": 0
  //     }, {
  //       "name": "stamp4.png",
  //       "url": "https://hashsnap-reports.s3.ap-northeast-2.amazonaws.com/uploaded-posts/663052f1-be37-4e2e-99fb-b1e54f819f3d.png",
  //       "x": 850,
  //       "y": 1150,
  //       "w": 250,
  //       "h": 250,
  //       "r": 0
  //     }, {
  //       "name": "stamp5.png",
  //       "url": "https://hashsnap-reports.s3.ap-northeast-2.amazonaws.com/uploaded-posts/9d43a6b0-4650-4bb8-84ad-0db736d2fb9a.png",
  //       "x": 850,
  //       "y": 1150,
  //       "w": 250,
  //       "h": 250,
  //       "r": 0
  //     }]
  //   }]
  // },
  "langPack": {
    "lang": "Korea",
    "data": []
  },
  "mosaic": {
    "use": false,
    "cols": 23,
    "rows": 10,
    "width": 177.3,
    "height": 104.6,
    "top": 1.5,
    "left": 1.02,
    "mosaicImage": "https://hashsnap-reports.s3.ap-northeast-2.amazonaws.com/uploaded-posts/39d07916-a864-4603-9cea-5e6b45a0d932.png"
  }
}