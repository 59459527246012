<template>
  <div class='setting-panel' v-bar>
    <div class='setting-contents'>
      <div class='setting-body'>
        <p>Printing is an AD page that shows client's AD(Videos, Images...) while printing a photo.</p>
        <div class='hr'></div>
        <p>Duplicate settings.</p>
        <hs-select :options="['None', 'Kiosk 1']" v-model="setting.copy"></hs-select>  
      </div>
      <div class='setting-title'>
        Layout
      </div>
      <div class='setting-body'>
        <p><b>Upload Image</b></p>
        <div>
          <dt>Background</dt>
          <dd>
            <hs-file
              style='width: 100%;'
              v-model="setting.backgroundImage"
              :guide="{ width: 1200, height: 1800 }"></hs-file>
          </dd>
        </div>
        <div>
          <hs-checkbox
            v-model="useMainBackground"
            @change="checkMainBackground(useMainBackground)">Use background image like main</hs-checkbox>
        </div>
      </div>
      <div class='setting-title'>
        Advertisment
      </div>
      <div class='setting-body'>
        <!-- <p><b>Upload AD(Video or Image)</b></p> -->
        <div>
          <dt>Advertisement</dt>
          <dd>
            <hs-file
              style='width: 100%;'
              :accept="'image/*, video/*'"
              v-model="setting.advertisement" @change="changeAdvertisementType"></hs-file>
          </dd>
        </div>
        <div>
          <dt>Width</dt>
          <dd><hs-number v-model="setting.advertisementWidth" :maxlength="4" :min="500" :max="1080">px</hs-number></dd>
        </div>
        <div>
          <dt>Height</dt>
          <dd><hs-number v-model="setting.advertisementHeight" :maxlength="4" :min="500" :max="1920">px</hs-number></dd>
        </div>
        <div>
          <dt>Top Margin</dt>
          <dd>
            <hs-number v-model="setting.paddingTop" :maxlength="3" :min="0" :max="1920">px</hs-number>
          </dd>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        useMainBackground: false
      }
    },
    methods: {
      changeAdvertisementType(e) {
        this.setting.type = e.type.replace(/\/.*/g, '');
      },
      checkMainBackground(bool) {
        if (bool) {
          this.$background = this.setting.backgroundImage;
          this.setting.backgroundImage = this.$parent.printerSetting['postView'].backgroundImage;
        }
        else {
          this.setting.backgroundImage = this.$background;
        }
      }
    },
    computed: {
      setting() {
        return this.$parent.printerSetting[this.$parent.currentTab];
      },
    }
  };
</script>

<style lang='scss' scoped>

  dt, dd {
    margin-bottom: 20px;
    display: inline-block;
    vertical-align: middle;
  }
  dt {
    min-width: 100px;
    width: 30%;
  }
  dd {
    width: 60%;
  }
</style>