<template>
  <div class='setting-panel' v-bar>
    <div class='setting-contents'>
      <div class='setting-body'>
        <p>Custom is optional page that is able to connect URL which contains AD, Game, or Survey.</p>
        <div class='hr'></div>
        <hs-checkbox v-model="setting.skip">Skip this page</hs-checkbox>   
      </div>
      <template v-if="!setting.skip">
        <div class='setting-title'>
          Layout
        </div>
        <div class='setting-body'>
          <p><b>Type</b></p>
          <p><hs-select :options="['Passive Advertisting']" v-model="setting.type"></hs-select></p>
          <p><b>Upload Image</b></p>
          <div>
            <dt>Background</dt>
            <dd>
              <hs-file
                style='width: 100%;'
                v-model="setting.backgroundImage"
                :guide="{ width: 1080, height: 1920 }"></hs-file>
            </dd>
          </div>
          <div>
            <hs-checkbox
              v-model="useMainBackground"
              @change="checkMainBackground(useMainBackground)">Use background image like main</hs-checkbox>
          </div>
        </div>
        <div class='setting-title'>
          Timing
        </div>
        <div class='setting-body'>
          <p><b>Transfer to Next page after</b></p>
          <p><hs-number v-model="setting.outTime" :maxlength="1" :min="3" :max="20">sec</hs-number></p>
          <p><hs-checkbox v-model="setting.clickable">Clickable for go to Next Page</hs-checkbox></p>
          <p><b>Page Location</b></p>
          <p>Drag "Custom" page and drop to the location you want to show this page (Save & Apply to activate).</p>
          <hs-list v-model="rootSetting.tabFlow"></hs-list>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        useMainBackground: false
      }
    },
    methods: {
      checkMainBackground(bool) {
        if (bool) {
          this.$background = this.setting.backgroundImage;
          this.setting.backgroundImage = this.$parent.printerSetting['postView'].backgroundImage;
        }
        else {
          this.setting.backgroundImage = this.$background;
        }
      }
    },
    computed: {
      setting() {
        return this.$parent.printerSetting[this.$parent.currentTab];
      },
      rootSetting() {
        return this.$parent.printerSetting;
      }
    },
  };
</script>

<style lang='scss' scoped>
  dt, dd {
    margin-bottom: 20px;
    display: inline-block;
    vertical-align: middle;
  }
  dt {
    min-width: 100px;
    width: 30%;
  }
  dd {
    width: 60%;
  }
</style>