var pushedElement = null;
var ox;
var oy;
var deltas = { x: 0, y: 0 };

function Draggable () {
  function dragdown(e) {
    // console.log('down!!', e, draggableElements);
    // return;
    let path = [];
    if (e.path) {
      path = e.path
    } else {
      let { target } = e;
      while(path[path.length - 1] !== document.body) {
        path.push(target);
        target = target.parentElement;
      }
    }
    for (var i = 0, item; item = path[i]; i++) {
      if (draggableElements.indexOf(item) !== -1) {
        pushedElement = item;
        ox = e.pageX;
        oy = e.pageY;
        if (pushedElement && pushedElement.draggable_down) pushedElement.draggable_down(deltas, e, pushedElement);
        e.preventDefault();
        break;
      }
    }
  }

  function dragmove(e) {
    if (pushedElement) {
      deltas.x = e.pageX - ox;
      deltas.y = e.pageY - oy;
      ox = e.pageX;
      oy = e.pageY;
      if (pushedElement && pushedElement.draggable_move) pushedElement.draggable_move(deltas,e, pushedElement);
    }
  }
  
  function dragup(e) {
    if (pushedElement && pushedElement.draggable_up) pushedElement.draggable_up(deltas, e, pushedElement);
    pushedElement = null;
    deltas = { x: 0, y: 0 };
  }

  var draggableElements = [];

  document.addEventListener('mousedown', dragdown);
  document.addEventListener('touchstart', dragdown);
  document.addEventListener('mousemove', dragmove);
  document.addEventListener('touchmove', dragmove);
  document.addEventListener('mouseup', dragup);
  document.addEventListener('touchend', dragup);

  this.addElement = function (el) {
    if (draggableElements.indexOf(el) === -1) draggableElements.push(el);
  };

  this.removeElement = function (el) {
    var idx = draggableElements.indexOf(el);
    if (idx !== -1) {
      // console.log('remove', draggableElements, idx);
      draggableElements.splice(idx, 1);
      // console.log('removed', draggableElements);
    }
  }
}

// Draggable.prototype.addMethod = function (name, f) {
//   this.method[name] = f;
// }

var draggable = new Draggable();
export default draggable;