<template>
  <div>
    <div
      v-for="(list, index) of eventList"
      :key="list">
    <dt>{{ list }} ~ {{ list + 1 }}</dt>  
    <dd><hs-number v-model="value[index]" :maxlength="4" :min="0" :max="1000">prize</hs-number></dd>
    </div>
  </div>
</template>

<script>
export default {
  model: {
    event: "change"
  },
  props: ['in-time', 'out-time', 'value'],
  computed: {
    eventList() {
      if (this.outTime <= this.inTime) return [];
      var list = [];
      for (var i = this.inTime; i < this.outTime; i++) {
        list.push(i);
      }
      return list;
    },
  }
}
</script>

<style lang="scss" scoped>
  dt, dd {
    display: inline-block;
  }
</style>
