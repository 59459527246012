<template>
  <div class='hs-input'>
    <input
      :type='type'
      :value="value"
      :placeholder="placeholder"
      @blur="returnValue"
      @click="resetValue"
      @input="wrapValue"
      />
  </div>
</template>

<script>
export default {
  model: {
    event: 'change'
  },
  props: {
    // id: {
    //   type: String,
    //   default: function () {
    //     return `y-checkbox-${ this._uid }`;
    //   }
    // },
    type: {
      type: String,
      default: 'text'
    },
    placeholder: String,
    reset: Boolean,
    value: String,
  },
  data() {
    return {

    }
  },
  methods: {
    returnValue(e) {
      e.target.value = this.value;
    },
    resetValue(e) {
      if (this.reset) {
        e.target.value = '';
      }
    },
    wrapValue(e) {
      this.$emit('change', e.target.value);
      // e.preventDefault();
    }
  },
}
</script>


<style lang="scss" scoped>
  div {
    display: table-cell;
    width: 100%;
    background: white;
  }
  .hs-input {
    position: relative;
    display: inline-block;
    border: 1px solid #ccc;
    padding: 5px;
    border-radius: 5px;
    box-sizing: border-box;
  }
  input {
    width: 100%;
    border: 0;
    vertical-align: middle;
  }
</style>

